import React, { useState, useEffect } from "react";
import { AXIOS_GET } from "../../../config/axios";
import { GET_CLINIC_CITIES_URL } from "../../../helper/Url";

function PlaceAutocomplete({
  placeholder = "CODE POSTAL OU VILLE",
  updateLocation,
  value,
  onChange,
  name,
  setAddress,
  setSearchPostal,
  setCity
}) {
  const [inputValue, setInputValue] = useState(value || '');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    AXIOS_GET(GET_CLINIC_CITIES_URL).then((res) => {
      const formattedData = res.data.map(item => ({
        city: formatText(item.city),
        postalCode: item.postalCode
      }));
      setSuggestions(formattedData);
      setCities(formattedData);
    });
  }, []);

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    setShowSuggestions(true);
    
    // Only filter suggestions, don't update other state yet
    setSuggestions(
      cities.filter(item => 
        normalizeString(`${item.city}, ${item.postalCode}`).includes(normalizeString(newValue))
      )
    );
  };

  const handleSuggestionClick = (suggestion) => {
    const formattedValue = `${suggestion.city}, ${suggestion.postalCode}`;
    setInputValue(formattedValue);
    setShowSuggestions(false);
    
    // Update all related state only when a valid option is selected
    if (onChange) {
      onChange({ target: { name, value: formattedValue } });
    }
    if (setAddress) setAddress(formattedValue);
    if (setCity) {
      setCity(suggestion.city);
      setSearchPostal(suggestion.postalCode);
    }
  };

  const handleInputClick = () => {
    setShowSuggestions(true);
    setSuggestions(cities); // Show all cities when clicking the input
  };

  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setTimeout(() => setShowSuggestions(false), 100);
    }
  };

  const clearSelection = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setInputValue('');
    setSuggestions(cities); // Reset to all cities
    setShowSuggestions(false);
    
    // Clear all related state
    if (onChange) {
      onChange({ target: { name, value: '' } });
    }
    if (setAddress) {
      setAddress('');
    }
    if (setCity) {
      setCity('');
      setSearchPostal('');
    }
    if (updateLocation) {
      updateLocation(null);
    }
  };

  // Function to remove accents and normalize text
  const normalizeString = (str) => {
    return str
      .normalize("NFD") // Decomposes accents (e.g., é → e +  ́)
      .replace(/[\u0300-\u036f]/g, "") // Removes diacritical marks
      .toLowerCase();
  };

  // Function to format text with capitalized words
  const formatText = (str) => {
    return normalizeString(str)
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <div style={{ position: "relative" }} className=" w-full" onBlur={handleBlur}>
      <div className="relative w-full">
        <input
          type="text"
          autoComplete="off"
          onChange={handleInputChange}
          onClick={handleInputClick}
          onFocus={handleInputClick}
          value={inputValue}
          className={`w-full h-full px-4 py-2 hover:text-gray-300 transition hover:placeholder:text-gray-700 text-sm outline-none font-normal cursor-pointer placeholder:font-normal text-black bg-white ${showSuggestions ? 'placeholder:text-gray-400' : 'placeholder:text-black' }`}
          placeholder={placeholder}
          name={name}
        />
        {inputValue && (
          <div
            onClick={clearSelection}
            className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
            style={{ 
              width: "20px",
              height: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#e0e0e0",
              borderRadius: "50%",
              color: "#666",
              fontSize: "14px",
              zIndex: 1000,
              pointerEvents: "auto"
            }}
          >
            ✕
          </div>
        )}
      </div>
      {showSuggestions && suggestions.length > 0 && (
        <div className="absolute top-full left-0 right-0 bg-white border-x-2 border-x-[#2CB5B2] z-[1000] max-h-[300px] overflow-y-auto mt-2 shadow-md">
          {suggestions.map((suggestion, index) => (
            <div
              key={index}
              className={`
                cursor-pointer text-sm text-black hover:text-[#2CB5B2] font-normal
                ${index === 0 ? 'border-t border-t-[#2CB5B2]' : ''}
              `}
              onClick={() => handleSuggestionClick(suggestion)}
              style={{
                padding: '8px 16px',
                width: '80%',
                margin: '0 auto',
                textAlign: 'left',
              }}
            >
              {suggestion.city}, {suggestion.postalCode}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default PlaceAutocomplete;
