import React from "react";
import addIcon from "../../../assets/Icons/add_pupple.png";
import Profile from "../../../assets/Images/profile.png";
import PersonIcon from "@mui/icons-material/Person";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ScheduleIcon from "@mui/icons-material/Schedule";
import PetsIcon from "@mui/icons-material/Pets";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useNavigate } from "react-router-dom";
import { AXIOS_GET } from "../../../config/axios";
import { GET_APPOINTMENTS_URL, GET_PROVIDERS_URL } from "../../../helper/Url";
import { useEffect } from "react";
import { useState } from "react";
import ErrorToast from "../../../helper/Message/ErrorToast";
import { Base_Url } from "../../../config/variables";
import Loader from "../../../helper/Loader/Loader";
import { set } from "react-hook-form";

const Board = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("user_token");
  const [Vets, setVets] = useState([]);
  const [Appointments, setAppointments] = useState([]);
  const [getvetloader, setgetvetloader] = useState(false);

  const getProviders = () => {
    setgetvetloader(true);
    AXIOS_GET(GET_PROVIDERS_URL, token)
      .then((res) => {
        setVets(res?.data);
        setgetvetloader(false);
      })
      .catch((error) => {
        setgetvetloader(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const getTodayAppontments = () => {
    AXIOS_GET(`${GET_APPOINTMENTS_URL}?day=today`, token)
      .then((res) => {
        setAppointments(res?.data);
      })
      .catch((error) => {
        ErrorToast(error);
      });
  };

  const formatTime = (hour) => {
    const formattedHour = String(hour).padStart(2, "0");
    const formattedMinutes = "00";
    return `${formattedHour}:${formattedMinutes}`;
  };

  const TimeDisplay = (hour) => {
    return formatTime(hour);
  };

  const redirectVet = (id) => {
    localStorage.setItem("vet_click_id", id);
    navigate(`/clinic/dashboard/veterinary`);
  };

  useEffect(() => {
    getProviders();
    getTodayAppontments();
  }, []);

  return (
    <div className="content-container">
      <h1 className="welcome-title">
        Bienvenue <br />
        <span style={{ fontSize: "25px" }} className="text_purple">
          Espace
        </span>{" "}
        en un clic
      </h1>
      <br />
      <h1 className="page-name sm-page-name text_purple">Les vétérinaires</h1>
      <div className="page-image-container">
        {Vets.length > 0
          ? Vets?.map((item, data) => {
              return (
                <>
                  <div
                    className="pet-data"
                    onClick={() => redirectVet(item._id)}
                  >
                    <div className="img-holder">
                      <img
                        className="border-2 border-[#8B7BCB] rounded-full"
                        src={
                          item.picture.includes("https")
                            ? item.picture
                            : `${Base_Url}/${item.picture}`
                        }
                        alt=""
                      />
                    </div>
                    <span className="text_purple">
                      {item.firstname} {item.lastname}
                    </span>
                  </div>
                </>
              );
            })
          : getvetloader && (
              <div className="pet-data">
                <div className="img-holder">
                  <Loader color="#8b7bcb" />
                </div>
              </div>
            )}

        <div
          className="pet-data"
          onClick={() => navigate("/clinic/dashboard/veterinary/action")}
        >
          <div className="img-holder">
            <img src={addIcon} className="addIcon" alt="" />
          </div>
          <span className="text_purple">Ajouter un vétérinaire</span>
        </div>
      </div>
      <p className="remarks">
        Vous pouvez aussi retrouver le détails et modifier la fiche de chaque
        vétérinaire en cliquant sur sa photo ou dans la barre latérale, dans la
        rubrique Mes vétérinaires.
      </p>

      <div className="rendezvous-passes">
        <h1 className="rendezvous-title text_purple">
          Les rendez-vous du jour
        </h1>

        <p
          className="main-color mg-bm-6 text_purple"
          style={{ textTransform: "capitalize" }}
        >
          {new Date().toLocaleDateString("fr-FR", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </p>
        {Appointments.length > 0 ? (
          <>
            {Appointments?.map((item, data) => {
              return (
                <div className="rendezvous_item">
                  <div className="rendezvous_img">
                    <div className="img_container">
                      <img
                        src={item?.pet?.picture ? Base_Url + `/${item?.pet?.picture}` : item?.species === "Chat" ? Base_Url + `/uploads/defaults/PNG/oneclic.vet-chat.png` : item?.species === "Chien" ? Base_Url + `/uploads/defaults/PNG/oneclic.vet-chien.png` : Base_Url + `/uploads/defaults/PNG/oneclic.vet-nac.png`}
                        alt="Pet"
                      />
                    </div>
                    <span className="item_data">
                      <PetsIcon /> {item?.species}
                      {item?.pet?.name ? ` - ${item?.pet?.name}` : ""}
                    </span>
                  </div>
                  <div className="rendezvous_address">
                    <span className="item_data">
                      <PersonIcon />
                      Dr. {item?.provider?.firstname} {item?.provider?.lastname}
                    </span>
                  </div>
                  <div className="rendezvous_address">
                    <span className="item_data">
                      <CalendarMonthIcon />{" "}
                      {new Date(item?.date).toISOString().slice(0, 10)}
                    </span>
                    <span className="item_data">
                      <ScheduleIcon /> {TimeDisplay(item?.start?.hours)}
                    </span>
                  </div>
                  <div className="rendezvous_action">
                    {item?.pet ? (
                      <button
                        onClick={() =>
                          navigate(
                            `/clinic/dashboard/animals/${item?.pet?._id}`
                          )
                        }
                      >
                        Lire la fiche de {item?.pet?.name}
                      </button>
                    ) : (
                      <button
                        className="no-pet"
                        style={{
                          color: "red",
                          background: "white",
                          border: "2px solid #dc404042",
                          cursor: "none",
                        }}
                      >
                        L’animal n’est pas enregistré
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
            <button
              className="btn_action"
              onClick={() => navigate("/clinic/dashboard/rendezvous")}
            >
              voir plus de rdv
            </button>
          </>
        ) : (
          "Vous n’avez aucun rendez-vous aujourd’hui."
        )}
      </div>
    </div>
  );
};

export default Board;
