import React, { useEffect } from "react";
import House from "../../../assets/Icons/clinic_house.png";
import flag1 from "../../../assets/Icons/flag1.png";
import flag2 from "../../../assets/Icons/flag2.png";
import flag3 from "../../../assets/Icons/flag3.png";
import flag4 from "../../../assets/Icons/flag4.png";
import tick from "../../../assets/Icons/Vector.png";

import calendar from "../../../assets/Icons/calendar_blue.png";
import time from "../../../assets/Icons/time_blue.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import WarningImage from "../../../assets/Icons/Warning.png";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBookinginfo,
  setBookingInfo,
} from "../../../features/SearchSlice";
import {
  GET_PETS_URL,
  GET_SINGLE_CLIENTS_URL,
  POST_APPOINTMENT_URL,
} from "../../../helper/Url";
import { AXIOS_GET, AXIOS_POST } from "../../../config/axios";
import { useState } from "react";
import SuccessToast from "../../../helper/Message/SuccessToast";
import ErrorToast from "../../../helper/Message/ErrorToast";
import { Base_Url } from "../../../config/variables";
import Loader from "../../../helper/Loader/Loader";
import { set } from "react-hook-form";
import { ValidateValue } from "../../../helper/Message/ValidateValue";

export const ConfirmCard = () => {
  const navigate = useNavigate();
  const bookingData = useSelector(selectBookinginfo).payload;
  const dispatch = useDispatch();
  const token = localStorage.getItem("user_token");
  const [petsData, setpetsData] = useState([]);
  const [petInfo, setpetInfo] = useState();
  const [loading, setLoading] = useState(false);

  console.log("bookingData", bookingData);
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      clinic: `${bookingData.id}`,
      Client: `${localStorage.getItem("user_id")}`,
      service: `${bookingData.service._id}`,
      provider: `${bookingData.vet._id}`,
      date: `${bookingData.selectedDate}`,
      start: bookingData.startTimeObj,
      species: `${bookingData.animalType}`,
      pet: petInfo?._id,
      petName: petInfo?.name,
      status: "Pending",
      notes: `${bookingData.notes}`,
    };
    dispatch(
      setBookingInfo({
        ...bookingData,
        pet: petInfo,
        petName: petInfo?.name,
        Client: localStorage.getItem("user_id"),
        date: bookingData.selectedDate,
      })
    );

    // get user information first and check if he has phone number or not

    AXIOS_GET(GET_SINGLE_CLIENTS_URL + localStorage.getItem("user_id"), token)
      .then((res) => {
        if (res?.data?.telephone?.length > 2) {
          localStorage.setItem("user_phone", res.data.telephone);
          AXIOS_POST(POST_APPOINTMENT_URL, data, token)
            .then((res) => {
              setLoading(false);
              localStorage.setItem(
                "booking_data",
                JSON.stringify({
                  ...bookingData,
                  pet: petInfo,
                  petName: petInfo?.name,
                  Client: localStorage.getItem("user_id"),
                  date: bookingData.selectedDate,
                })
              );
              dispatch(setBookingInfo(undefined));
              console.log("new res", res.data);
              if(!res.data?.filemakerSync){
               SuccessToast(res.data?.message)
              }
              else{
                SuccessToast("Demande envoyée avec succès");
              }
              setTimeout(() => {
                navigate("/search/confirmed-booking");
              }, 300);
            })
            .catch((error) => {
              setLoading(false);
              ErrorToast(error);
            });
        } else {
          setLoading(false);
          let updateInfo = window.confirm(
            "Voulez-vous mettre à jour vos informations ?"
          );
          if (updateInfo) {
            navigate("/customer/dashboard/customerclinicinfo");
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("The response error is :", error);
      });
  };

  const backTonextCard = () => {
    navigate("/search/next-booking");
  };

  const getAnimals = () => {
    AXIOS_GET(GET_PETS_URL, token)
      .then((res) => {
        setpetsData(res.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (bookingData === undefined) {
      navigate("/");
    }
    getAnimals();
  }, []);

  function formatDate(inputDate) {
    var parts = inputDate.split("-");
    if (parts.length === 3) {
      var formattedDate = parts[2] + "-" + parts[1] + "-" + parts[0];
      return formattedDate;
    } else {
      return inputDate;
    }
  }

  return (
    <>
      {bookingData?.selectedDate.length > 0 && (
        <div className="confirm-card-container-no-center">
          <ToastContainer
            position="top-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <div className="holder">
            <p className="notification">
              <img src={WarningImage} alt="" />
              Votre rendez-vous n’est pas encore confirmé. Merci de compléter et
              vérifier les informations ci-dessous pour confirmer votre
              rendez-vous.
            </p>
            <div className="detail-holder">
              <div className="main section section1">
                <div className="checker checker1 ruler">
                  <p>Pour qui prenez-vous rendez-vous ?</p>
                  <div className="checkbox_group">
                    {petsData.length > 0 ? (
                      petsData
                        ?.filter((pet) => pet.species === pet.species)
                        .map((pet, index) => {
                          return (
                            <>
                              <label
                                className="checkbox_wrapper"
                                htmlFor={`checkbox1${index}`}
                              >
                                <div className="pet_checkbox"></div>
                                <span>{pet?.name}</span>
                                <input
                                  type="checkbox"
                                  className="pet_hidden_check"
                                  id={`checkbox1${index}`}
                                  onChange={(e) =>
                                    setpetInfo(e.target.checked ? pet : "")
                                  }
                                  hidden
                                />
                                {petInfo?._id === pet._id && (
                                  <img src={tick} alt="" />
                                )}
                              </label>
                            </>
                          );
                        })
                    ) : (
                      <p>Vous n'avez pas encore d'animaux</p>
                    )}
                  </div>
                </div>
                <div className="checker checker2 ruler">
                  <p>Vos informations de rendez-vous</p>
                  <div className="pinfo">
                    <p>
                      Type d’animal :{" "}
                      <span>
                        {bookingData.animalType === "Chat"
                          ? "Chat"
                          : bookingData.animalType === "Chien"
                          ? "Chien"
                          : "NAC"}
                      </span>
                    </p>
                    <p>
                      Motif du rendez-vous :{" "}
                      <span>{bookingData.service?.name}</span>
                    </p>
                    <p>
                      Précisions sur le rendez-vous :{" "}
                      <span>{bookingData.notes}</span>
                    </p>
                  </div>
                </div>
                <div className="btn-confirm-action">
                  {loading ? (
                    <button className="active">
                      <Loader color="white" />
                    </button>
                  ) : (
                    <button className="active" onClick={handleSubmit}>
                      Confirmer le rendez-vous
                    </button>
                  )}
                  <button onClick={backTonextCard}>
                    Modifier les informations
                  </button>
                </div>
              </div>

              <div className="section section2 ruler">
                <div className="search-info">
                  <div className="intro">
                    <div className="img-container">
                      <img
                        src={
                          bookingData?.vet?.picture.includes("http")
                            ? bookingData.vet.picture
                            : `${Base_Url}/${bookingData.vet.picture}`
                        }
                        alt="pet"
                      />
                    </div>
                    <div className="details">
                      <h2> {bookingData.vet?.name} </h2>
                      <h4> Vétérinaire </h4>
                      <div className="language">
                        {bookingData.vet.language.map((item, index) => (
                          <>
                            <img src={item == "French" && flag1} />
                            <img src={item == "English" && flag2} />
                            <img src={item == "German" && flag3} />
                            <img src={item == "Italian" && flag4} />
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="details-info">
                    <div className="vet-address">
                      <div className="logo">
                        <img src={House} />
                      </div>
                      <div className="info">
                        <p className="address">Votre clinique :</p>
                      </div>
                    </div>
                    <div className="address-detail">
                      <p className="address1">
                        Clinique <span>{bookingData?.facilityName}</span>
                      </p>
                      <p className="address2">
                        {<ValidateValue value={bookingData.address} />}{" "}
                        {/* {bookingData.postalcode} {bookingData.city} - */}
                        {/* {<ValidateValue value={bookingData.country} />} */}
                      </p>
                    </div>
                  </div>
                  <div className="details-info">
                    <div className="vet-address">
                      <div className="info">
                        <p className="address">Date et heure du rendez-vous</p>
                      </div>
                    </div>
                    <div className="vet-address excpt">
                      <div className="logo">
                        <img src={calendar} />
                      </div>
                      <div className="info">
                        <p className="address">{bookingData.selectedDate}</p>
                      </div>
                    </div>
                    <div className="vet-address excpt">
                      <div className="logo">
                        <img src={time} />
                      </div>
                      <div className="info">
                        <p className="address">{bookingData.selectedTime}</p>
                      </div>
                    </div>
                  </div>
                  <div className="vet-schedule-meeting"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
