import React from "react";
import House from "../../../assets/Icons/clinic_house.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import calendar from "../../../assets/Icons/calendar_blue.png";
import time from "../../../assets/Icons/time_blue.png";
import WarningImage from "../../../assets/Icons/Warning.png";
import Tick from "../../../assets/Icons/tick.png";
import {
  selectBookinginfo,
  setBookingInfo,
} from "../../../features/SearchSlice";
import { useEffect } from "react";
import { AXIOS_POST } from "../../../config/axios";
import {
  GET_CLINIC_TOKEN_VALIDITY_URL,
  POST_PROVIDER_AVAILABLE_URL,
  POST_SERVICE_SPECIES_URL,
} from "../../../helper/Url";
import { useState } from "react";
import ErrorToast from "../../../helper/Message/ErrorToast";
import SuccessToast from "../../../helper/Message/SuccessToast";
import BasicModal from "../../../helper/Modal/Modal";
import Loader from "../../../helper/Loader/Loader";
import SuccessError from "../../../helper/Message/SuccessError";
import { Base_Url } from "../../../config/variables";
import { get, set } from "react-hook-form";
import TickWhite from "../../../assets/Icons/tickWhite.png";
import { ValidateValue } from "../../../helper/Message/ValidateValue";
import axios from "axios";

export const ConfirmCard = () => {
  const navigate = useNavigate();
  const bookingData = useSelector(selectBookinginfo).payload;
  const dispatch = useDispatch();
  const [vetlist, setvetlist] = useState([]);
  const [animalType, setAnimalType] = useState(
    localStorage.getItem("searchSpecies")
  );
  const [reason, setReason] = useState(localStorage.getItem("searchService"));
  const [vet, setVet] = useState(localStorage.getItem("searchProvider"));
  const [notes, setNotes] = useState();
  const [servicelist, setservicelist] = useState([]);
  const [openm, setopenm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serviceLoading, setServiceLoading] = useState(false);
  const [providerLoading, setProviderLoading] = useState(false);

  const handleSubmit = async (e) => {
    setLoading(true);
    if (e) {
      e.preventDefault();
    }
    const data = bookingData;
    let serviveObj = servicelist.filter(
      (item, index) =>
        item._id == bookingData?.selectedService[0]._id || item._id == reason
    );
    let vetObj = vetlist.filter(
      (item, index) =>
        item._id == bookingData?.selectedProvider[0]._id || item._id == vet
    );

    if (
      servicelist.find((item, index) => item._id === reason) &&
      vetlist.find((item, index) => item._id === vet)
    ) {
      dispatch(
        setBookingInfo({
          ...data,
          animalType,
          service: serviveObj[0],
          vet: vetObj[0],
          notes,
          servicelist,
          vetlist,
        })
      );
      const token = localStorage.getItem("user_token");
      try {
        const response = await axios.post(`${Base_Url}/api/v1${GET_CLINIC_TOKEN_VALIDITY_URL}`, {
          token
        });
        const isVerified = response.data.isVerified && response.data.exp > Math.floor(Date.now() / 1000);

        const user_accountType = localStorage.getItem("user_accountType");
        if (!token || !isVerified) {
          setopenm(true);
        } else {
          setTimeout(() => {
            setLoading(false);
            if (user_accountType == 0) {
              navigate("/search/confirm-booking");
            } else {
              SuccessError(
                "Vous ne pouvez pas réserver avec le compte de l'entreprise, veuillez réserver avec le compte client."
              );
            }
          }, 500);
        }
      } catch (error) {
        console.log("error", error);
        setopenm(true);
      }
    } else if (
      !servicelist.find(
        (item, index) => item._id === reason || item.status === true
      )
    ) {
      setLoading(false);
      toast.warning(
        "Le service n'est pas disponible ou Veuillez sélectionner un service",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "colored",
        }
      );
    } else {
      setLoading(false);
      toast.warning(
        "Le vétérinaire n'est pas disponible ou Veuillez sélectionner un vétérinaire",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "colored",
        }
      );
    }
  };

  // Get vets
  const getvets = (e) => {
    setProviderLoading(true);
    setReason(e.target.value);
    let data = {
      startTime: bookingData?.startTimeObj,
      endTime: bookingData?.endTimeObj,
      day: new Date(bookingData?.selectedDate).getDay(),
      date: formatDate(bookingData?.selectedDate),
      service: e.target.value,
      clinic: bookingData?.id,
    };
    
    if (bookingData !== undefined) {
      AXIOS_POST(POST_PROVIDER_AVAILABLE_URL, data)
        .then((res) => {
          setvetlist(res.data);
        })
        .catch((error) => {
          ErrorToast(error);
        })
        .finally(() => {
          setProviderLoading(false);
        });
    } else {
      setProviderLoading(false);
      navigate("/");
    }
  };

  const getservice = (e, typeDeanimal = null) => {
    setServiceLoading(true);
    setAnimalType(typeDeanimal != null ? typeDeanimal : e.target.value);
    let data = {
      startTime: bookingData?.startTimeObj,
      endTime: bookingData?.endTimeObj,
      date: formatDate(bookingData?.selectedDate),
      species: typeDeanimal != null ? typeDeanimal : e.target.value,
      clinic: bookingData?.id,
    };
    
    if (bookingData !== undefined) {
      AXIOS_POST(POST_SERVICE_SPECIES_URL, data)
        .then((res) => {
          if (!res.data.find((item) => item._id == JSON.parse(localStorage.getItem("selectedService"))?._id)) {
            localStorage.removeItem("selectedService");
            setReason("");
            setVet("");
            setvetlist([]);
            setservicelist([]);
          }
          setservicelist(res.data);
        })
        .catch((error) => {
          console.log("error", error);
          ErrorToast(error);
        })
        .finally(() => {
          setServiceLoading(false);
        });
    } else {
      setServiceLoading(false);
      navigate("/");
    }
  };

  function formatDate(inputDate) {
    if (inputDate == undefined) {
      navigate("/");
    } else {
      // const dateString = inputDate.split("-");
      // const isoDateString = `${dateString[2]}-${dateString[1]}-${dateString[0]}`; // Rearrange parts for YYYY-MM-DD

      const dateObject = new Date(inputDate);
      const isoString = dateObject.toISOString();

      return isoString;
    }
  }

  const closeModel = () => {
    setLoading(false);
    setopenm(false);
  };

  useEffect(() => {
    if (bookingData === undefined) {
      navigate("/");
    } else {
      if (bookingData?.servicelist?.length > 0) {
        setservicelist(bookingData.servicelist);
        setvetlist(bookingData.vetlist);
        setVet(bookingData.vet._id);
        setReason(bookingData.service._id);
        setAnimalType(bookingData.animalType);
        setNotes(bookingData.notes);
      } else {
        setVet(bookingData.selectedProvider[0]._id);
        setReason(bookingData.selectedService[0]._id);
        setvetlist(bookingData.selectedProvider);
        setservicelist(bookingData.selectedService);
        if (animalType) {
          getservice(null, animalType);
        }
      }
    }
  }, []);

  return (
    <>
      {bookingData != undefined && (
        <div className="confirm-card-container">
          <ToastContainer
            position="top-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <BasicModal openm={openm} closeModel={closeModel} />
          <div className="holder">
            <p className="notification">
              <img src={WarningImage} alt="" />
              Votre rendez-vous n'est pas encore confirmé
            </p>
            <div className="confirm-form">
              <div className="tile">
                <div className="tick">
                  <img src={Tick} alt="" />
                </div>
                <div className="number active">
                  1
                  <img src={TickWhite} alt="" />
                </div>
                <div className="content">
                  <div className="info-section">
                    <div className="image-holder">
                      <img
                        src={
                          bookingData?.picture.includes("http")
                            ? bookingData.picture
                            : `${Base_Url}/${bookingData.picture}`
                        }
                        alt=""
                      />
                    </div>
                    <div className="info-holder">
                      <p className="address">
                        {" "}
                        <img src={House} /> Votre clinique
                      </p>
                      <p className="address">
                        {" "}
                       <span> {bookingData?.facilityName}</span>
                      </p>
                      <p
                        className="address"
                        style={{ fontWeight: 400, fontSize: "14px" }}
                      >
                        {<ValidateValue value={bookingData?.address} />}{" "}
                        {/* {bookingData?.postalcode} {bookingData?.city} -{" "} */}
                        {/* {bookingData?.country === "Switzerland" ||
                        bookingData?.country === "Suisse" ? (
                          "Suisse"
                        ) : (
                          <ValidateValue value={bookingData?.country} />
                        )} */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tile">
                <div className="tick">
                  {animalType && <img src={Tick} alt="" />}
                </div>
                <div className={animalType ? "number active" : "number"}>
                  2{animalType && <img src={TickWhite} alt="" />}
                </div>
                <div className="content">
                  <p>
                    Sélectionnez le type d'animal concerné par le rendez-vous{" "}
                    <span className="symbol">*</span>
                  </p>
                  <select
                    name="animalType"
                    value={animalType}
                    onChange={(e) => getservice(e)}
                  >
                    <option disabled selected value={""}>
                      Sélectionnez le type d'animal
                    </option>
                    <option value={"Chat"}>Chat</option>
                    <option value={"Chien"}>Chien</option>
                    <option value={"NAC"}>NAC</option>
                  </select>
                </div>
              </div>
              <div className="tile">
                <div className="tick">
                  {reason && <img src={Tick} alt="" />}
                </div>
                <div className={reason ? "number active" : "number"}>
                  3{reason && <img src={TickWhite} alt="" />}
                </div>
                <div className="content">
                  <p>
                    Sélectionnez le motif du rendez-vous{" "}
                    <span className="symbol">*</span>
                  </p>
                  {serviceLoading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                      <Loader />
                    </div>
                  ) : (
                    <select
                      name="reason"
                      value={reason}
                      onChange={(e) => getvets(e)}
                    >
                      <option value="" disabled selected>
                        Sélectionnez le service
                      </option>
                      {servicelist?.map((item, index) => (
                        <option value={item._id} disabled={!item.status}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </div>
              <div className="tile">
                <div className="tick">{vet && <img src={Tick} alt="" />}</div>
                <div className={vet ? "number active" : "number"}>
                  4{vet && <img src={TickWhite} alt="" />}
                </div>
                <div className="content">
                  <p>
                    Sélectionnez le vétérinaire souhaité{" "}
                    <span className="symbol">*</span>
                  </p>
                  {providerLoading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                      <Loader />
                    </div>
                  ) : (
                    <select
                      name="vet"
                      value={vet}
                      onChange={(e) => setVet(e.target.value)}
                    >
                      <option value="" disabled selected>
                        Sélectionnez un vétérinaire
                      </option>
                      {vetlist.map((item, index) => (
                        <option value={item._id} disabled={!item.isAvailable}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </div>
              <div className="tile">
                <div className="tick">{notes && <img src={Tick} alt="" />}</div>
                <div className={notes ? "active number" : "number"}>
                  5{notes && <img src={TickWhite} alt="" />}
                </div>
                <div className="content">
                  <p>Précisez le motif de la consultation </p>
                  <input
                    type="text"
                    name="notes"
                    required
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    placeholder="Mon chat a mal au ventre"
                  />
                </div>
              </div>
              <div className="tile">
                <div className="tick">
                  <img src={Tick} alt="" />
                </div>
                <div
                  className={
                    bookingData.selectedDate ? "active number" : "number"
                  }
                >
                  6
                  <img src={TickWhite} alt="" />
                </div>
                <div className="content">
                  <div>
                    <h2 className="mb-4">Date et heure du rendez-vous</h2>
                    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 sm:gap-8">
                      {/* Left side with date and time */}
                      <div className="flex flex-col sm:flex-row sm:items-center gap-3 sm:gap-8">
                        <div className="flex items-center">
                          <img src={calendar} alt="calendar" className="w-5 h-5" />
                          <span className="ml-2 text-base leading-none">{bookingData.selectedDate}</span>
                        </div>

                        <div className="flex items-center">
                          <img src={time} alt="time" className="w-5 h-5" />
                          <span className="ml-2 text-base leading-none">{bookingData.selectedTime}</span>
                        </div>
                      </div>

                      {/* Right side with button */}
                      <div className="confirm">
                        {loading ? (
                          <button className="w-full sm:w-auto">
                            <Loader color="white" />
                          </button>
                        ) : (
                          animalType &&
                          reason &&
                          vet && (
                            <button 
                              className="w-full sm:w-auto bg-[#2CB5B2] text-white px-8 py-2 rounded-sm text-base" 
                              type="submit" 
                              onClick={handleSubmit}
                            >
                              SUIVANT
                            </button>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="symbol-detail">
              <p>
                <span className="symbol">*</span> Champs obligatoires
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
