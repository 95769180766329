import "../../../styles/client/account/Forget.scss";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AXIOS_POST } from "../../../config/axios";
import { POST_FORGET_URL } from "../../../helper/Url";
import { useState } from "react";
import Loader from "../../../helper/Loader/Loader";
import Translate from "../../../config/Translate";
import FormErrors from "../../../helper/Message/FormErrors";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../features/LanguageSlice";
import ErrorToast from "../../../helper/Message/ErrorToast";
import { selectBookinginfo } from "../../../features/SearchSlice";
import EditIcon from "../../../assets/Icons/edit_icon.png";
import flag2 from "../../../assets/Icons/flag2.png";
import flag3 from "../../../assets/Icons/flag3.png";
import flag4 from "../../../assets/Icons/flag4.png";
import tick from "../../../assets/Icons/Vector.png";
import calendar from "../../../assets/Icons/calendar_blue.png";
import time from "../../../assets/Icons/time_blue.png";
import { Base_Url } from "../../../config/variables";
import House from "../../../assets/Icons/clinic_house.png";
import flag1 from "../../../assets/Icons/flag1.png";


function Forget() {
  const navigate = useNavigate();
  const [isSubmitted, setisSubmitted] = useState(false);
  const bookingData = useSelector(selectBookinginfo).payload;
  const languageValue = useSelector(selectLanguage);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  let requestError =
    languageValue === "En"
      ? "Something went wrong"
      : "Quelque chose s’est mal passé";
  let requestSuccess =
    languageValue === "En"
      ? "Check your Email to reset password"
      : "Vérifiez votre adresse e-mail pour réinitialiser le mot de passe";

  const onSubmit = (data) => {
    setisSubmitted(true);
    AXIOS_POST(POST_FORGET_URL, data)
      .then((res) => {
        toast.success(requestSuccess, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,  
          progress: false,
          theme: "colored",
        });
        setisSubmitted(false);
      })
      .catch((error) => {
        ErrorToast(requestError);
        setisSubmitted(false);
      });
  };  
  return (
    <div className="forget-container">
      <ToastContainer />
      <div className="forget-wrapper"> 
       <h1 className="font-20 black">
       Connexion à <span className="main-color">Oneclic.vet</span>
        </h1>
        <h1 className="main-color">
          <Translate Word={"page.forget.password"} />
        </h1>
        <span className="black forget-dialog text-center">
        Entrez l’adresse e-mail que vous avez <br/>utilisé lors de la création de votre <br/>compte. Vous allez recevoir un e-mail <br/>pour réinitialiser votre mot de passe.<br/>
N’oubliez-pas de vérifier dans vos spams. 
        </span>
       <div className="forget-action-container">
       <div className="section1">
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            type="email"
            placeholder="Entrez votre adresse e-mail"
            {...register("email", { required: true })}
          />
          {isSubmitted ? (
            <button type="button">
              <Loader color="white" />
            </button>
          ) : (
            <button>
              ENVOYER
            </button>
          )}
        </form>
        </div>
        {bookingData?.notes?.length > 0 && (
            <div className="section2">
              <div className="search-info">
                <div className="lert">
                  Votre rendez-vous n’est pas encore confirmé.
                </div>
                <div className="intro">
                  <div className="img-container">
                    <img
                      src={
                        bookingData?.vet?.picture
                          ? bookingData?.vet?.picture.includes("http")
                            ? bookingData.vet.picture
                            : `${Base_Url}/${bookingData?.vet?.picture}`
                          : "https://cdn.vectorstock.com/i/1000x1000/08/75/cute-yorkshire-terrier-dog-avatar-vector-20670875.webp%22"
                      }
                      alt="" 
                    />
                  </div>
                  <div className="details">
                    <h2> {bookingData.vet.name} </h2>
                    <h4> Vétérinaire </h4>
                    <div className="language">
                      {bookingData.vet.language.map((item, index) => (
                       <>
                        <img src={item == "French" && flag1} />
                        <img src={item == "English" && flag2} />
                        <img src={item == "German" && flag3} />
                        <img src={item == "Italian" && flag4} />
                       </>
                      ))}
                    </div> 
                  </div>
                </div>
                <div className="details-info">
                  <div className="vet-address">
                    <div className="logo">
                      <img src={House} />
                    </div>  
                    <div className="info">
                      <p className="address">Votre clinique :</p>
                    </div>
                  </div>
                  <div className="address-detail">
                    <p className="address1">
                      Clinique{" "}
                      <span>
                        {bookingData?.facilityName}
                      </span>
                    </p>
                    <p className="address2">
                      {bookingData.country}- {bookingData.city}
                    </p>
                  </div>
                </div>
                <div className="details-info">
                  <div className="vet-address">
                    <div className="info">
                      <p className="address">Date et heure du rendez-vous</p>
                    </div>
                  </div>
                  <div className="vet-address excpt" style={{ alignItems:"start" }}>
                    <div className="logo">
                      <img src={calendar} /> 
                    </div>
                    <div className="info">
                      <p className="address">{bookingData.selectedDate}</p>
                    </div>
                  </div>
                  <div className="vet-address excpt" style={{ alignItems:"start" }}>
                    <div className="logo">
                      <img src={time} />
                    </div>
                    <div className="info">
                      <p className="address">{bookingData.selectedTime}</p>
                    </div>
                  </div>
                </div>

                <button
                  className="button-edit"
                  onClick={() => navigate("/search/next-booking")}
                >
                  <img src={EditIcon} alt="" />
                  éditer
                </button>
              </div>
            </div>
          )}
       </div>
        
      </div>
    </div>
  );
}

export default Forget;
