import React from "react";

import TrashIconred from "../../../../assets/Icons/trash-icon-red.png";
import PersonIcon from "@mui/icons-material/Person";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ScheduleIcon from "@mui/icons-material/Schedule";
import PetsIcon from "@mui/icons-material/Pets";
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Base_Url } from "../../../../config/variables";

const RendezvousCard = ({ item, deleteAppointment }) => {
  const navigate = useNavigate();
  const [edit, editVets] = useState(false);

  const formatTime = (time) => {
    const formattedHour = String(time.hours).padStart(2, "0");
    const formattedMinutes = String(time.minutes).padStart(2, "0");
    return `${formattedHour}:${formattedMinutes}`;
  };

  const TimeDisplay = (time) => {
    return formatTime(time);
  };

  const dateToreadable = (date) => {
  const inputString = date.split("-")
  const year = inputString[0]
  const month = inputString[1]
  const day = inputString[2]
  return `${day}-${month}-${year}`
  }
  
  

  return (
    <div className="rendezvous_item">
      <div className="rendezvous_img">
        <div className="img_container">
          <img
            src={item.pet?.picture ? Base_Url + `/${item.pet.picture}` : item.pet?.species === "Chat" ? Base_Url + `/uploads/defaults/PNG/oneclic.vet-chat.png` : item.pet?.species === "Chien" ? Base_Url + `/uploads/defaults/PNG/oneclic.vet-chien.png` : Base_Url + `/uploads/defaults/PNG/oneclic.vet-nac.png`}
            alt=""
          />
        </div>
        <span className="item_data">
          <PetsIcon /> {item?.species}
          {item?.pet?.name ? ` - ${item?.pet?.name}` : ""}
        </span>
      </div>
      <div className="rendezvous_address">
        <span className="item_data">
          {edit ? (
            <div className="changeitemvalue">
              <PersonIcon />
              <select name="" id="">
                <option value="">
                  {item?.provider?.firstname} {item?.provider?.lastname}
                </option>
                <option value=""></option>
              </select>
            </div>
          ) : (
            <>
              <PersonIcon />
               Dr. {" "}
              {item?.provider?.firstname} {item?.provider?.lastname}
            </>
          )}
        </span>
        {/* {edit == true ? <button onClick={()=>editVets(false)} >Enregistrer</button> : <button onClick={()=>editVets(true)}>Modifier </button>} */}
      </div>
      <div className="rendezvous_address">
        <span className="item_data">
          {edit ? (
            <div className="changeitemvalue">
              <CalendarMonthIcon />
              <input
                type="date"
                value={new Date(item?.date).toISOString().slice(0, 10)}
              />
            </div>
          ) : (
            <>
              <CalendarMonthIcon />{" "}
              {dateToreadable(new Date(item?.date).toISOString().slice(0, 10))}
            </>
          )}
        </span>
        <span className="item_data">
          {edit ? (
            <div className="changeitemvalue">
              <ScheduleIcon />
              <select name="" id="">
                <option value="">
                  <ScheduleIcon />
                  {TimeDisplay(item?.start)}
                </option>
              </select>
            </div>
          ) : (
            <>
              <ScheduleIcon />
              {TimeDisplay(item?.start)}
            </>
          )}
        </span>
        
        <span className="item_data">
          <div
            className="edititemvalue"
            onClick={() => deleteAppointment(item._id)}
          >
            <img src={TrashIconred} alt="" srcset="" />
            <span>Supprimer le rendez-vous</span>
          </div>
        </span>
      </div>
      <div className="rendezvous_action">
        {item?.pet ? (
          <button
            onClick={() =>
              navigate(`/clinic/dashboard/animals/${item?.pet?._id}`)
            }
          >
            Lire la fiche de {item?.pet?.name}
          </button>
        ) : (
          <button
            className="no-pet"
            style={{
              background: "white !important",
              border: "2px solid #8B7BCB !important",
              color: "#8B7BCB !important",
              cursor: "none",
            }}
          >
            L’animal n’est pas enregistré
          </button>
        )}
      </div>
    </div>
  );
};

export default RendezvousCard;