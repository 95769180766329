import "../../../styles/client/common/Header.scss";
import logo from "../../../assets/Logos/logo-white.png";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Link, useNavigate } from "react-router-dom";
import Menucomp from "./Menu";
import { useEffect, useState } from "react";
import Translate from "../../../config/Translate";
import LanguageSwitcher from "../../common/LanguageSwitcher";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Person } from "@mui/icons-material";
import { ValidateValue } from "../../../helper/Message/ValidateValue";
import { selectUserInfo } from "../../../features/UserInfoSlice";
import { useSelector } from "react-redux";

function Header() {
  const navigate = useNavigate();
  const [showMenu, setShowmenu] = useState(false);
  const [userFirstname, setUserFirstname] = useState("");
  const [userLastname, setUserLastname] = useState("");
  const selectUserinfoData = useSelector(selectUserInfo);

  const hidemenu = () => {
    setShowmenu(false);
  };
  const RedirectToDashboard = () => {
    const getuserRole = localStorage.getItem("user_accountType");
    if (getuserRole == 1) {
      navigate("/clinic/dashboard/summary");
    }
    if (getuserRole == 0) {
      navigate("/customer/dashboard/summary");
    }
  };

  const loggedIn = () => {
    const user = localStorage.getItem("user_token");

    if (user != undefined && user != null) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (window.innerWidth > 800) { // Only add scroll listener for desktop
      window.addEventListener("scroll", (e) => {
        if (window.scrollY > 2) {
          document.querySelector(".header-container").classList.add("scrolled");
        } else {
          document.querySelector(".header-container").classList.remove("scrolled");
        }
      });
      return () => window.removeEventListener("scroll", () => {});
    }
  }, []);

  // change get user firstname and lastname whenever localstorage change

  useEffect(() => {
    if (localStorage.getItem("user_firstname")) {
      setUserFirstname(localStorage.getItem("user_firstname"));
    }
    if (localStorage.getItem("user_lastname")) {
      setUserLastname(localStorage.getItem("user_lastname"));
    }
  }, [selectUserinfoData]);

  return (
    <div className="header-container">
      <div className="logo-container">
        <Link to="/">
          <img src={logo} alt="Logo" onClick={() => navigate("/")} />
        </Link>
      </div>
      <div className="action-container">
        <div className="action-btn">
          <MenuIcon
            className="humberger-menu"
            onClick={() => setShowmenu(true)}
          />
        </div>
      </div>
      {showMenu && <Menucomp setShowmenu={hidemenu} />}
    </div>
  );
}

export default Header;
