import React, { useEffect, useState } from 'react';
import { FaMapMarkerAlt, FaClock, FaUserMd, FaStethoscope, FaPhone } from 'react-icons/fa';
import House from "../../../assets/Icons/clinic_house.png";
import Map from '../common/Map';
import { Base_Url } from '../../../config/variables';
import { AXIOS_GET, AXIOS_PUT } from '../../../config/axios';
import { GET_CLINIC_SERVICES_URl, GET_CLINIC_TIMETABLE_URL, GET_PROVIDERS_CLINIC_PROVIDER_URL, GET_PROVIDERS_URL, GET_SERVICES_URL, GET_SERVICS_CLINIC_URL, GET_SINGLE_CLINIC_URL, GET_SINGLE_SERVICE_URL } from '../../../helper/Url';
import Loader from '../../../helper/Loader/Loader';
import ClinicInactivePageContent from './ClinicInactivePageContent';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setBookingInfo } from '../../../features/SearchSlice';

function ClinicActivePageContent() {

  const clinic = JSON.parse(localStorage.getItem("clinic"));
  const width = window.innerWidth;
  const [clinicTimetable, setClinicTimetable] = useState([]);
  const [clinicVeterinarians, setClinicVeterinarians] = useState([]);
  const [clinicServices, setClinicServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  
//   021 802 82 82
  const formatPhoneNumber = (phoneNumber) => {
    return phoneNumber?.replace(/(\d{2})(\d{2})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3 $4 $5');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [timetableRes, veterinariansRes, servicesRes] = await Promise.all([
          AXIOS_GET(`${GET_CLINIC_TIMETABLE_URL}${clinic._id}`),
          AXIOS_GET(`${GET_PROVIDERS_CLINIC_PROVIDER_URL}${clinic._id}`),
          AXIOS_GET(`${GET_SERVICS_CLINIC_URL}${clinic._id}`)
        ]);

        setClinicTimetable(timetableRes.data);
        setClinicVeterinarians(veterinariansRes.data);
        setClinicServices(servicesRes.data);
        setIsActive(clinic.isActive);
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoading(false);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const getDayOpeningHours = (day) => {
    const dayOpeningHours = clinicTimetable.find(item => item.dayOfWeek === day && item.status === true);
    if (dayOpeningHours) {
      const start = `${dayOpeningHours.operatingHours.startTime.hours.toString().padStart(2, '0')}h${dayOpeningHours.operatingHours.startTime.minutes.toString().padStart(2, '0')}`;
      const end = `${dayOpeningHours.operatingHours.endTime.hours.toString().padStart(2, '0')}h${dayOpeningHours.operatingHours.endTime.minutes.toString().padStart(2, '0')}`;
      return `${start} - ${end}`;
    }
    return "Fermé";
  };

//   Date must be tomorrow
  const today = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0];
  const day = new Date(new Date().setDate(new Date().getDate() + 1)).toLocaleDateString('fr-FR', { weekday: 'long' });
  const selectedTime = new Date().toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
//   time object is like {hours: 10, minutes: 30}
  const currentTime = new Date().toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
  const startTimeObj = {hours: currentTime.split(':')[0], minutes: currentTime.split(':')[1]};
  const endTimeObj = {hours: currentTime.split(':')[0], minutes: currentTime.split(':')[1]};
  const handleSubmit = (e) => {    
    e.preventDefault(); 
    localStorage.setItem("selectedcliniqueId", clinic?._id);
    setLoading(true);
    dispatch(
      setBookingInfo({
        id: clinic?._id,
        firstname: clinic.firstname,
        lastname: clinic.lastname,
        city: clinic.city,
        country: clinic.country,
        postalcode: clinic.postalCode,
        address: clinic.address,
        picture: clinic.picture,
        facilityName: clinic.facilityName,
        latitude: clinic.latitude,
        longitude: clinic.longitude,
        selectedDate: today,
        day,
        selectedTime,
        startTimeObj,
        endTimeObj,
        selectedProvider: clinicVeterinarians,
        selectedService: clinicServices,
      })
    );
    setTimeout(() => {
      setLoading(false);
      localStorage.removeItem("selectedcliniqueId");
      navigate("/search/next-booking");
    }, 0);
  };

  return (
    <>
    {isLoading ? (
      <div className="flex justify-center py-10">
        <Loader />
      </div>
    ) : isActive ? (
      <div className="w-4/5 mx-auto mt-10 sm:px-8 px-4 py-10">
        {/* Clinic Header */}
        <div className="flex items-center gap-3 mb-4">
            <img src={`${Base_Url}/${clinic.picture}`} alt="Clinic Logo" className="w-20 h-20 rounded-full border-2 border-[#2CB5B2]" />
            <div className="flex flex-col items-start gap-2">
                <img src={House} alt="Clinic Icon" className="w-6 h-6" />
                <h1 className="text-2xl font-bold text-left">
                    <span className="text-[#2CB5B2]">{clinic.facilityName}</span>
                </h1>
            </div>
        </div>
        <p className="text-black font-semibold my-8">
          { clinic.otherDescription } 
        </p>
        {/* <button onClick={handleSubmit} className="bg-[#2CB5B2] text-white py-2 px-4 mb-6">
          PRENDRE RENDEZ-VOUS
        </button> */}

        {/* Information Sections */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Hours Section */}
          <div className="border-2 border-[#2CB5B2] rounded p-4" style={{border: "1px solid #2CB5B2"}}>
            <h2 className="text-lg font-bold text-[#2CB5B2] mb-4 flex items-center">
                <FaClock className="mr-2" /> Nos horaires
            </h2>
            {loading ? <div className="flex justify-center items-center h-screen">
              <Loader />
            </div> : (
            <ul className="flex text-black flex-col gap-2">
              {['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'].map((day, index) => (
                <li key={index} className="flex">
                  <span className="font-bold text-[#2CB5B2] w-24">{day} :</span>
                  <span className="ml-2">{getDayOpeningHours(index+1)}</span>
                </li>
              ))}
            </ul>
            )}
          </div>

          {/* Address Section */}
          <div className="border-2 border-[#2CB5B2] rounded p-4" style={{border: "1px solid #2CB5B2"}}>
            <h2 className="text-lg font-bold text-[#2CB5B2] mb-4 flex items-center">
                 Nos coordonnées
            </h2>

            <div className="flex flex-row">
            <FaMapMarkerAlt className="text-[#2CB5B2] text-lg mt-1 mr-2" />
            <p className="text-black mr-2 flex flex-col">
                <span className="text-black">{clinic.address}</span>
                </p>
            </div>
            {/* align horizontal */}
            <p className="flex items-center mt-8"> <span className="mr-2 text-[#2CB5B2]"><FaPhone className="mr-2" /></span><span className="text-black">{formatPhoneNumber(clinic.telephone1)}</span></p>
            <div className="mt-4 w-full h-[200px] overflow-hidden">
              <Map
                height={width < 1200 ? 200 : "calc(100% - 40px)"}
                address={clinic.address}
                city={clinic.city}
                postalCode={clinic.postalCode}
              />
            </div>
          </div>

          {/* Veterinarians Section */}
          <div className="border-2 border-[#2CB5B2] rounded p-4" style={{border: "1px solid #2CB5B2"}}>
            <h2 className="text-lg font-bold text-[#2CB5B2] mb-8 flex items-center">
              <FaUserMd className="mr-2" /> Nos vétérinaires
            </h2>
            {loading ? <div className="flex justify-center items-center h-screen">
              <Loader />
            </div> : (
            <div className="flex flex-row gap-4">
                {clinicVeterinarians.length === 0 ? (
                  <p className="text-black">Aucun vétérinaire trouvé</p>
                ) : (
                  clinicVeterinarians.map((veterinarian) => (
                    <div className="text-center">
                  <img src={`${Base_Url}/${veterinarian.picture}`} alt={veterinarian.firstName} className="w-16 h-16 rounded-full mx-auto border-1 border-[#2CB5B2]"  style={{border: "1px solid #2CB5B2"}}/>
                  <p className="mt-2 text-[#2CB5B2] font-semibold">{veterinarian.firstname} {veterinarian.lastname}</p>
                </div>
              ))
            )}
            </div>
            )}
            </div>

          {/* Services Section */}
          <div className="border-2 border-[#2CB5B2] rounded p-4" style={{border: "1px solid #2CB5B2"}}>
            <h2 className="text-lg font-bold text-[#2CB5B2] mb-8 flex items-center">
              <FaStethoscope className="mr-2" /> Nos services
            </h2>
            <div className="flex flex-row gap-4">
                {/* if no service */}
                {clinicServices.length === 0 ? (
                  <p className="text-black">Aucun service trouvé</p>
                ) : (
                  clinicServices.map((service) => (
                    <div className="text-center">
                    <img src={`${Base_Url}/${service.picture[0]}`} alt={service.name} className="w-16 h-16 rounded-full mx-auto border-1 border-[#2CB5B2]"  style={{border: "1px solid #2CB5B2"}}/>
                  <p className="text-[#2CB5B2] font-semibold mt-2" >{service.name}</p>
                </div>
              ))
            )}
            </div>  
          </div>
        </div>
      </div>
    ) : (
      <ClinicInactivePageContent />
    )}
    </>
  );
}

export default ClinicActivePageContent;

  