import React, { useState } from "react";
import House from "../../../assets/Icons/clinic_house.png";
import flag1 from "../../../assets/Icons/flag1.png";
import flag2 from "../../../assets/Icons/flag2.png";
import flag3 from "../../../assets/Icons/flag3.png";
import flag4 from "../../../assets/Icons/flag4.png";
import tick from "../../../assets/Icons/Vector.png";

import calendar from "../../../assets/Icons/calendar_blue.png";
import phone from "../../../assets/Icons/phone_blue.png";
import time from "../../../assets/Icons/time_blue.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Map from "../../../components/client/common/Map";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBookinginfo,
  setBookingInfo,
} from "../../../features/SearchSlice";
import { useEffect } from "react";
import { Base_Url } from "../../../config/variables";
import TickWhite from "../../../assets/Icons/tickWhite.png";
import Loader from "../../../helper/Loader/Loader";
import { ValidateValue } from "../../../helper/Message/ValidateValue";

export const ConfirmedCard = () => {
  const navigate = useNavigate();
  const [bookingData, setbookingData] = useState(
    JSON.parse(localStorage.getItem("booking_data"))
  );

  const dispatch = useDispatch();
  const navigatef = (path) => {
    navigate(path);
  };

  useEffect(() => {
    if (bookingData === undefined) {
      navigate("/");
    }
  }, []);

  function formatDate(inputDate) {
    var parts = inputDate.split("/");
    if (parts.length === 3) {
      var formattedDate = parts[2] + "-" + parts[1] + "-" + parts[0];
      return formattedDate;
    } else {
      return inputDate;
    }
  }

  function formatNumber(number) {
    // Remove any non-digit characters from the input number
    const cleanNumber = number.replace(/\D/g, "");

    // Check if the number starts with '41'
    if (cleanNumber.startsWith("41")) {
      // Format the number as +41 xx xxx xx xx
      return `+41 ${cleanNumber.slice(2, 4)} ${cleanNumber.slice(
        4,
        7
      )} ${cleanNumber.slice(7, 9)} ${cleanNumber.slice(9)}`;
    } else {
      // Return the original number if it doesn't start with '41'
      return number;
    }
  }

  return (
    <>
      {bookingData?.selectedDate.length > 0 ? (
        <div className="confirm-card-container-no-center">
          <ToastContainer
            position="top-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <div className="holder">
            <div className="notf-container">
              <p className="notification">
                <img src={TickWhite} alt="" /> Le rendez-vous{" "}
                {bookingData?.petName && ` de ${bookingData?.petName} `} est
                confirmé !
              </p>
            </div>
            <div className="detail-holder">
              <div className=" main section section2 main2">
                <div className="search-info confirmed-box">
                  <div className="search-container">
                    <div className="details mg-bm-40">
                      <h2 className="main-color">
                        {" "}
                        À propos de votre rendez-vous{" "}
                      </h2>
                    </div>
                    <div className="intro">
                      <div className="img-container">
                        <img
                          src={
                            bookingData?.vet?.picture.includes("htt")
                              ? bookingData.vet.picture
                              : `${Base_Url}/${bookingData.vet.picture}`
                          }
                          alt=""
                        />
                      </div>
                      <div className="details">
                        <h2> {bookingData.vet.name} </h2>
                        <h4> Vétérinaire </h4>
                        <div className="language">
                          {bookingData.vet.language.map((item, index) => (
                            <>
                              <img src={item == "French" && flag1} />
                              <img src={item == "English" && flag2} />
                              <img src={item == "German" && flag3} />
                              <img src={item == "Italian" && flag4} />
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="details-info">
                      <div className="vet-address excpt">
                        <div className="logo">
                          <img src={calendar} />
                        </div>
                        <div className="info">
                          <p className="address">
                            {formatDate(bookingData.selectedDate)}
                          </p>
                        </div>
                      </div>
                      <div className="vet-address excpt">
                        <div className="logo">
                          <img src={time} />
                        </div>
                        <div className="info">
                          <p className="address">{bookingData.selectedTime}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="search-container">
                    <div className="details mg-bm-40">
                      <h2 className="main-color"> Informations pratiques </h2>
                    </div>
                    <div className="details-info">
                      <div className="vet-address">
                        <div className="logo">
                          <img src={House} />
                        </div>
                        <div className="info">
                          <p className="address">Votre clinique :</p>
                        </div>
                      </div>
                      <div className="address-detail">
                        <p className="address1">
                          Clinique <span>{bookingData.facilityName}</span>
                        </p>
                        <p className="address2">
                          {<ValidateValue value={bookingData.address} />}{" "}
                          {/* {bookingData.postalcode} {bookingData.city}-
                          {<ValidateValue value={bookingData.country} />} */}
                        </p>
                      </div>
                    </div>
                    <div className="details-info">
                      <div className="vet-address excpt">
                        <div className="logo">
                          <img src={phone} />
                        </div>
                        <div className="info">
                          <p className="address">
                            {localStorage.getItem("user_phone")
                              ? formatNumber(localStorage.getItem("user_phone"))
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="search-container">
                    <Map
                      height="320px"
                      latitude={bookingData.latitude}
                      longitude={bookingData.longitude} 
                    />
                  </div>
                </div>

                <div className="btn-confirm-action">
                  <button
                    className="active"
                    onClick={() => {
                      dispatch(setBookingInfo(undefined));
                      navigatef("/search", { replace: true });
                      window.location.reload();
                    }}
                  >
                    Nouveau rendez-vous
                  </button>
                  <button
                    onClick={() => {
                      dispatch(setBookingInfo(undefined));
                      navigatef("/customer/dashboard/rendezvous", {
                        replace: true,
                      });
                    }}
                  >
                    voir mes rendez-vous
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{ height: "500px", display: "grid", placeContent: "center" }}
        >
          <div className="loader">
            <Loader size={20} />
          </div>
        </div>
      )}
    </>
  );
};
