import React, { useState, useEffect } from "react";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import { set } from "nprogress";

function GooglePlaceAutocomplete({
  placeholder = "Entrez un emplacement",
  updateLocation,
  value,
  onChange,
  setCordinate,
  setAddress,
}) {
  const [place, setPlace] = useState(null);

  useEffect(() => {
    if (place) {
      getGeolocation();
    }
  }, [place]);

  const onLoad = (autocomplete) => {
    autocomplete.addListener("place_changed", () => {
      const selectedPlace = autocomplete.getPlace();
      setPlace(selectedPlace);
    });
  };

  const getGeolocation = () => {
    if (place && place.geometry && place.geometry.location) {
      const { lat, lng } = place.geometry.location;
      
      // Get formatted address in French
      let formattedAddress = place.formatted_address;
      
      // Replace common English terms with French ones if needed
      formattedAddress = formattedAddress
        .replace(/Switzerland/g, 'Suisse')
        .replace(/Geneva/g, 'Genève')
        .replace(/Zurich/g, 'Zürich');

      if (updateLocation) updateLocation(lat(), lng(), formattedAddress);
      if (setCordinate)
        setCordinate({
          lat: lat(),
          lng: lng(),
          place: formattedAddress,
        });
      if (setAddress) setAddress(formattedAddress);
      return `Latitude: ${lat()}, Longitude: ${lng()}`;
    }
    return "no data";
  };

  useEffect(() => {
    getGeolocation();
  }, [setCordinate]);

  return (
    <Autocomplete
      onLoad={onLoad}
      onPlaceChanged={getGeolocation}
      options={{
        componentRestrictions: { country: "ch" },
        language: "fr",
        region: "CH",
        fields: ["address_components", "formatted_address", "geometry"]
      }}
    >
      <input
        type="text"
        autoComplete="off"
        onChange={onChange}
        value={value}
        className="border_purple"
        placeholder={placeholder}
      />
    </Autocomplete>
  );
}

export default GooglePlaceAutocomplete;
