import { useState } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useNavigate } from "react-router-dom";
import { POST_VERIFY_RESEND_URL, POST_VERIFY_URL } from "../../../helper/Url";
import { AXIOS_POST } from "../../../config/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../features/LanguageSlice";
import Loader from "../../../helper/Loader/Loader";
import Translate from "../../../config/Translate";
import "../../../styles/client/account/Verify.scss";


function Verify() {
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [isSubmitted, setIsSubmited] = useState(false);
  const languageValue = useSelector(selectLanguage);
  const [resend,setResend]=useState(false);
  let requestError =
    languageValue === "En"
      ? "Something went wrong"
      : "Quelque chose s’est mal passé";
  let accountVerified =
    languageValue === "En"
      ? "Account verified successful"
      : "Compte vérifié avec succès";
  let invalidOtp =
    languageValue === "En"
      ? "Invalid verification code"
      : "Code de vérification non valide";

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleComplete = (finalValue) => {};
  const submitForm = (e) => {
    e.preventDefault();
    setIsSubmited(true);
    if (value.length < 6) {
      toast.error(invalidOtp, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "colored",
      });
      setIsSubmited(false);
    } else {
      AXIOS_POST(POST_VERIFY_URL, {
        email: localStorage.getItem("user_email"),
        otpCode: value,
      })
        .then((res) => {
          toast.success(accountVerified, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
            theme: "colored",
          });
          setIsSubmited(false);
          localStorage.setItem("user_token", res.data?.token);
          localStorage.setItem("user_email", res.data?.user?.email);
          localStorage.setItem("authType", res.data?.user?.autheType);
          localStorage.setItem("user_accountType", res.data?.user?.type);
          localStorage.setItem("user_firstname", res.data?.user?.firstname !="" && res.data?.user?.firstname != undefined ? res.data?.user?.firstname : "");
          localStorage.setItem("user_lastname", res.data?.user?.lastname !="" && res.data?.user?.lastname != undefined ? res.data?.user?.lastname : "");
          localStorage.setItem("user_id", res.data?.user?.clientId);
          res.data?.user.type === 0 &&
            setTimeout(
              () => {
                if(localStorage.getItem("isnew")==1){
                  localStorage.removeItem("isnew");
                  navigate("/search/confirm-booking", { replace: true })
                }else{
                  navigate("/clientpreinfo", { replace: true })
                }
              },
              3500
            );
          res.data?.user.type === 1 &&
            setTimeout(
              () => navigate("/companypreinfo", { replace: true }),
              3500
            );
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
            theme: "colored",
          });
          setIsSubmited(false);
        });
    }
  };

  const resendOtp = () => {
    setResend(true);
    AXIOS_POST(POST_VERIFY_RESEND_URL, {
      email: localStorage.getItem("user_email"),
    })
      .then((res) => {
        setResend(false);
        toast.success("Code envoyé avec succès", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "colored",
        });
      })
      .catch((error) => {
        setResend(false);
        toast.error(requestError, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "colored",
        });
      });
  }

  return (
    <div className="verify-container">
      <ToastContainer />
      <div className="verify-wrapper">
        <h3 className="black font-16">Rejoignez <span className="main-color">Oneclic.vet</span></h3>
        <h1 className="main-color lg-size">
        Validation de votre inscription
        </h1>
        <span className="black verify-dialog">
        Vous allez recevoir un e-mail contenant un code à renseigner ci-dessous pour valider votre inscription.
        N’oubliez-pas de vérifier dans vos spams.
        </span>
        <form onSubmit={submitForm} className="customer_otp">
          <div className="otp_holder">
          <MuiOtpInput
            length={6}
            onComplete={handleComplete}
            value={value}
            onChange={handleChange}
            TextFieldsProps={{ placeholder: '',type: 'number',
            inputMode: 'numeric'}}
          />
          </div>
          
          {isSubmitted ? (
            <button type="button" >
              <Loader color="white" />
            </button>
          ) : (
            <button>
              VALIDER
            </button>
          )}
          <div className="black resend">Je n’ai pas reçu le code. {resend?<Loader/>:<span className="main-color resend-span" onClick={resendOtp}>Renvoyer le code.</span>}</div>
        </form>
      </div>
    </div>
  );
}

export default Verify;
