const translation = JSON.parse(`{
    "header.login":"Connexion",
    "header.Register":"Inscription",
    "header.ifveterian":"Vous êtes vétérinaire ?",
    "page.join":"Rejoignez",
    "page.register.firstname":"Prénom*",
    "page.register.lastname":"Nom*",
    "page.register.phone":"Numéro de téléphone*",
    "page.register.email":"E-mail*",
    "page.register.cemail":"Confirmer l’adresse e-mail*",
    "page.register.address":"Adresse*",
     "page.register.city":"Ville*",
     "page.register.postalCode":"Code postal*",
     "page.register.country":"Pays*",
     "page.register.password":"Mot de passe*",
     "page.register.cpassword":"Confirmer le mot de passe*",
     "page.register.terms":"Termes et conditions*",
       "page.register.adhere":"J’adhère aux termes et conditions de Oneclic.vet",
       "page.register.submit":"Envoyer",
       "page.register.firstname.error":"Le prénom est obligatoire",
       "page.register.lastname.error":"Le nom de famille est requis",
       "page.register.phone.error":"Le numéro de téléphone est requis",
       "page.register.email.error":"L’adresse e-mail est requise",
       "page.register.address.error":"L’adresse est requise",
       "page.register.city.error":"La ville est requise",
       "page.register.postalcode.error":"Le code postal est requis",
       "page.register.country.error":"Le pays est requis",
       "page.register.password.error":"Le mot de passe est requis",
       "page.register.cpassword.error":"Confirmez le match s’il vous plaît!",
       "page.register.cpmatch.error":"Le mot de passe ne correspond pas",
       "page.register.terms.error":"Des conditions générales sont requises",
       "page.register.passwordPatern.error":"Le mot de passe doit comporter au moins 8 caractères (1 majuscule, 1 chiffre et 1 symbole)",
       "page.register.verify":"Vérifier",
       "page.register.verifyUser":"Vérifier l’utilisateur",
       "page.reset.password":"Réinitialisation",
       "page.change.password":"Changement",
       "page.forget.password":"Mot de passe oublié?",
       "page.home.left.title":"Vous êtes vétérinaire ?",
        "page.home.right.title":"Je trouve mon vétérinaire !",
        "page.home.left.content":"Inscrivez-vous maintenant et simplifiez-vous la vie! Profitez d’une solution simple, intuitive et rapide pour vous connecter avec vos clients et optimiser votre temps. Découvrez notre plateforme innovante et notre nouvelle offre sans abonnement et sans engagement, conçue spécifiquement pour la profession vétérinaire.",
        "page.home.right.content":"Prenez rendez-vous en ligne avec votre vétérinaire 24/7 en quelques clics! Depuis n’importe quel appareil, prenez rendez-vous pour votre animal avec le vétérinaire disponible le plus près de chez vous. C’est simple, sécurisé et entièrement gratuit.",
          "page.home.left.button":"Inscription vétérinaire",
        "page.home.right.button":"Prends rendez-vous",
        "page.register.ide.error":"Un numéro d’identification est requis",
        "page.Bienvenue":"Bienvenue",
        "page.myClinicDashboard":"Mon tableau de bord en un clic",
        "page.myanimaux":"Mes animaux",
        "page.ajouteanimaux":"Ajouter un animal",
        "page.animaldetailmessage":"Vous pouvez aussi retrouver le détails et modifier la fiche de chaque animal en cliquant sur sa photo ou dans la barre latérale, dans la rubrique Mes animaux.",
        "page.prochainrendezvous":"Mon prochain rendez-vous",
        "page.prochainrendezvousrdv":"Prendre un autre rdv",
        "page.myaccountenunclinic":"Mon compte en un clic",
         "page.myemail":"Mon e-mail",
        "page.changemyemail":"Changer mon e-mail",
        "page.changemypassword":"Changer mon mot de passe",
        "page.validatemodification":"Valider les modifications",
        "page.myinfoenunclinic":"Mes informations en un clic",
          "page.civilite":"Civilité*",
        "page.datedenaissance":"Date de naissance*",
        "page.prenom":"Prénom*",
        "page.nodefamily":"Nom de famille*",
        "page.address":"Adresse*",
        "page.postalcode":"Code postal*",
        "page.ville":"Ville*",
        "page.pay":"Pays*",
        "page.telephone":"Téléphone",
        "page.search":"RECHERCHER",
        "page.confirm.email.error":"L’adresse e-mail ne correspond pas"
  }`);

export default translation;
