import React, { useState, useEffect } from "react";

function AnimalAutocomplete({
  placeholder = "TYPE D'ANIMAL",
  value,
  onChange,
  name,
  setAnimalType
}) {
  const [inputValue, setInputValue] = useState(value || localStorage.getItem("animalType") || '');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [animals] = useState(['Chat', 'Chien', 'NAC']);

  useEffect(() => {
    setInputValue(value || localStorage.getItem("animalType") || '');
  }, [value]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    setShowSuggestions(true);
    setSuggestions(animals.filter(animal => 
      animal.toLowerCase().includes(newValue.toLowerCase())
    ));

    if (onChange) {
      onChange({ target: { name, value: newValue } });
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion);
    setShowSuggestions(false);
    if (setAnimalType) setAnimalType(suggestion);
    localStorage.setItem("animalType", suggestion);
  };

  const handleFocus = () => {
    setShowSuggestions(true);
    setSuggestions(animals);
  };

  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setTimeout(() => setShowSuggestions(false), 100);
    }
  };

  const clearSelection = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setInputValue('');
    setSuggestions([]);
    setShowSuggestions(false);
    localStorage.removeItem("animalType");
    if (setAnimalType) setAnimalType('');
    if (onChange) onChange({ target: { name, value: '' } });
  };

  return (
    <div style={{ position: "relative" }} className=" w-full" onBlur={handleBlur}>
      <div className="relative flex ">
        <input
          type="text"
          autoComplete="off"
          onChange={handleInputChange}
          value={inputValue}
          className={`w-full h-full px-4 py-2 hover:text-gray-300 transition hover:placeholder:text-gray-700 text-sm outline-none font-normal cursor-pointer placeholder:font-normal text-black bg-white ${showSuggestions ? 'placeholder:text-gray-400' : 'placeholder:text-black' }`}
          placeholder={placeholder}
          name={name}
          onFocus={handleFocus}
        />
        {inputValue && (
          <button
            onClick={clearSelection}
            className="w-full absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
            style={{ 
              width: "20px",
              height: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#e0e0e0",
              borderRadius: "50%",
              color: "#666",
              fontSize: "14px",
              zIndex: 1000,
              pointerEvents: "auto"
            }}
          >
            ✕
          </button>
        )}
      </div>
      {showSuggestions && suggestions.length > 0 && (
        <div className="absolute top-full left-0 right-0 bg-white border-l-2 border-l-[#2CB5B2] z-[1000] max-h-[300px] overflow-y-auto mt-2 shadow-md">
          {suggestions.map((suggestion, index) => (
            <div
              key={index}
              className={`
                cursor-pointer text-sm text-black hover:text-[#2CB5B2] font-normal
                ${index === 0 ? 'border-t border-t-[#2CB5B2]' : ''}
              `}
              onMouseDown={() => handleSuggestionClick(suggestion)}
              style={{
                padding: '8px 16px',
                width: '80%',
                margin: '0 auto',
                textAlign: 'left',
              }}
            > 
              {suggestion}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default AnimalAutocomplete;
