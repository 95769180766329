import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AXIOS_POST } from "../../../../config/axios";
import { POST_REGISTER_GOOGLE_URL, POST_REGISTER_URL } from "../../../../helper/Url";
import { ToastContainer, toast } from "react-toastify";
import tick from "../../../../assets/Icons/Vector.png";
import "react-toastify/dist/ReactToastify.css";
import Translate from "../../../../config/Translate";
import Loader from "../../../../helper/Loader/Loader";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../../features/LanguageSlice";
import FormErrors from "../../../../helper/Message/FormErrors";
import "../../../../styles/client/account/MainRegister.scss";
import SuccessToast from "../../../../helper/Message/SuccessToast";
import Google from '../../../../assets/Icons/logo googleg 48dp.png';
import Facebook from '../../../../assets/Icons/Facebook Logo.png';
import Apple from '../../../../assets/Icons/Apple Logo.png';
import { authPop, loginWithApple, loginWithFacebook } from "../../../../firebase";
import ErrorToast from "../../../../helper/Message/ErrorToast";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import {ReactComponent as AddRed} from "../../../../assets/Icons/AddRed.svg";
import PhoneField from "../../../common/PhoneField";


const ClientRegister = () => {
  const navigate = useNavigate();
  const [isSubmited, setIsSubmited] = useState(false);
  const languageValue = useSelector(selectLanguage);
  const [passwordvisible, setpasswordvisible] = useState(false);
  const [cpasswordvisible, setcpasswordvisible] = useState(false);
  const [telephone, setTelephone] = useState("");

  let accountCreated =
    languageValue === "En"
      ? "Account created successful"
      : "Compte créé avec succès";

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    data.ide = "";
    data.type = 0;
    data.role = 10;
    data.latitude = 80;
    data.longitude = 120;
    data.telephone = telephone;
    setIsSubmited(true);
    AXIOS_POST(POST_REGISTER_URL, data, "", "")
      .then((res) => {
        localStorage.setItem("user_email", data.email);
        if (res.data.statusCode === 400) {
          SuccessToast(res.data.message)
        } else {
          SuccessToast(accountCreated)
          setTimeout(() => navigate("/verify", { replace: true }), 3500);
        }
        setIsSubmited(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "colored",
        });
        setIsSubmited(false);
      });
  };

  const googleAuth=async ()=>{
    const user = await authPop();
    let data ={};
    data.type = 0;
    data.role = 10;
    setIsSubmited(true);
    AXIOS_POST(POST_REGISTER_GOOGLE_URL+`?type=${data.type}&role=${data.role}`, data, user.accessToken, "")
      .then((res) => {
          SuccessToast(accountCreated)
          setTimeout(
            () => navigate("/login", { replace: true }),
            0
          );
        
        setIsSubmited(false);
      })
      .catch((error) => {
        ErrorToast(error);
        setIsSubmited(false);
      });
  }

  return (
    <div className="register-container">
      <ToastContainer />
      <div className="register-inner-wrapper" data-aos="zoom-out-up">
        <div className="top-form-title">
          <Translate Word={"page.join"} />
          <span className="span-colored">Oneclic.vet</span>
        </div>
        <h1 className="lg-size">
          <Translate Word={"header.Register"} />
        </h1>
        <p className="text-center p-description">
        <strong>Prenez rendez-vous en ligne avec votre vétérinaire 24h/24 et 7j/7 en quelques clics !
      </strong>
          <br />
          Depuis n'importe quel appareil, prenez rendez-vous pour votre animal avec le vétérinaire disponible le plus proche de chez-vous.

          <br />
          <br />
          C'est simple, sécurisé et entièrement gratuit
        </p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex-container form-container"
        >
          <div className="input-container">
            <div className="flex-container input-container">

              <div className="input-holder">
                <label>
                  <Translate Word={"page.register.email"} />
                </label>
                <input
                  type="email"
                  {...register("email", { required: true })}
                  placeholder="Votre e-mail*"
                />
                {errors.email?.type === "required" && (
                  <FormErrors>
                    <Translate Word={"page.register.email.error"} />
                  </FormErrors>
                )}
              </div>

              <div className="input-holder">
                <label>
                  <Translate Word={"page.register.cemail"} />
                </label>
                <input
                  type="email"
                  {...register("confirmEmail", {required: true, validate: (val) => {
                    if (watch("email") != val) {
                      return "Your passwords do no match";
                    }
                  } })}
                  placeholder="Confirmez votre e-mail*"
                />
                {errors.confirmEmail?.type === "required" && (
                  <FormErrors>
                    <Translate Word={"page.register.email.error"} />
                  </FormErrors>
                )}
                {errors.confirmEmail?.type === "validate" && (
                  <FormErrors>
                    <Translate Word={"page.confirm.email.error"} />
                  </FormErrors>
                )}
              </div>

            </div>

            <div className="flex-container input-container">
              <div className="input-holder">
                <label className="">
                  <Translate Word={"page.register.password"} />
                </label>
                <div className="vb_item ">
                <input
                  type={passwordvisible ? "text" : "password"}
                  {...register("password", {
                    required: true,
                    pattern:
                      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@)($%^&*-]).{8,}$/,
                  })}
                  placeholder="Choisissez votre mot de passe*"
                />
                 <div className="vb_icon " onClick={()=>setpasswordvisible(!passwordvisible)}>
                  {passwordvisible ? <VisibilityOutlined />:<VisibilityOffOutlined/>}
                </div>
                </div>
                {errors.password?.type === "required" && (
                  <FormErrors>
                    <Translate Word={"page.register.password.error"} />
                  </FormErrors>
                )}
                {errors.password?.type === "pattern" && (
                  <FormErrors>
                    <Translate Word={"page.register.passwordPatern.error"} />
                  </FormErrors>
                )}
              </div>
              <div className="input-holder">
                <label className="">
                  <Translate Word={"page.register.cpassword"} />
                </label>
                <div className="vb_item ">
                <input
                  className=""
                  type={cpasswordvisible ? "text" : "password"}
                  {...register("confirmPassword", {
                    required: true,
                    validate: (val) => {
                      if (watch("password") != val) {
                        return "Your passwords do no match";
                      }
                    },
                  })}
                  placeholder="Confirmez votre mot de passe*"
                />
                <div className="vb_icon " onClick={()=>setcpasswordvisible(!cpasswordvisible)}>
                  {cpasswordvisible ? <VisibilityOutlined />:<VisibilityOffOutlined/>}
                </div>
                </div>
                
                {errors.confirmPassword?.type === "required" && (
                  <FormErrors>
                    <Translate Word={"page.register.password.error"} />
                  </FormErrors>
                )}
                {errors.confirmPassword?.type === "validate" && (
                  <FormErrors>
                    <Translate Word={"page.register.cpmatch.error"} />
                  </FormErrors>
                )}
              </div>
            </div>
            <div className="flex-container input-container">

              <div style={{ width:"100%" }}>
               <label className="">
                  Téléphone*
                </label>
                {/* <div className="vb_item "> */}
                {/* <input
                  type="number"
                  autoComplete="off"
                  placeholder="Votre téléphone*"
                  {...register("telephone", {
                    required: "Le téléphone est requis",
                    pattern: {
                      value: /^41\d{9}$/,
                      message:
                        "Le téléphone doit commencer par 41 et avoir 11 chiffres (41xxxxxxxxx)",
                    },
                  })}
                /> */}
                {/* </div> */}
                <PhoneField 
                     containerStyle={{ width:"100%", height:"52px",marginBottom:"20px" }}
                     buttonStyle={{ borderColor:"#2CB5B2" }}  
                     inputStyle={{ width:"100%",height:"52px",borderColor:"#2CB5B2" }}
                     placeholder="Votre téléphone*"  
                     telephone={telephone}
                     settelephone={setTelephone}
                />

              {telephone === "" && (
              <FormErrors>
                Le téléphone est requis
             </FormErrors> 
          )}
             {errors.telephone?.type === "pattern" && (
              <FormErrors>
                Le téléphone doit commencer par 41 et avoir 11 chiffres (41xxxxxxxxx)
             </FormErrors>
             )}
              </div>
              <div className="input-holder">
                
              </div>
            </div>

            <div className="flex-container input-container">
              <div className="input-holder">
                <label>
                Politique de confidentialité*
                </label>
                <div className="terms-check">
                <div className="checkbox-group">
                <label className="checkbox_wrapper" htmlFor="checkbox1">
                  <div className="pet_checkbox"></div>
                  <span></span>
                  <input
                    type="checkbox"
                    className="pet_hidden_check"
                    id="checkbox1"
                    {...register("terms", { required: true })}
                    hidden
                  />
                  <img src={tick} alt="" />
                </label>
              </div>
                  <span>
                    Pour créer un compte, j'accepte les{' '}
                    <a
                      href="https://info.oneclic.vet/nlpd/" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="text-[#2CB5B2] hover:underline"
                    >
                      Politiques de confidentialités
                    </a>
                    {' '}de Oneclic.vet.
                  </span>
                </div>
                {errors.terms && (
                  <FormErrors>
                    <Translate Word={"page.register.terms.error"} />
                  </FormErrors>
                )}
              </div>
            </div>

            {isSubmited ? (
              <div className="submit-button">
                <button type="button">
                  <Loader color="white"/>
                </button>
              </div>
            ) : (
              <div className="submit-button">
                <button>
                  S’inscrire
                </button>
              </div>
            )}
          </div>
        </form>
        <div className="authbtn-group">
         {/* <button className="other-login other-login1" onClick={facebookAuth}> <img src={Facebook} alt="" />Se connecter avec Facebook</button> */}
         {/* <button className="other-login other-login2" onClick={googleAuth}> <img src={Google} alt="" />Se connecter avec Google</button> */}
         {/* <button className="other-login other-login3" onClick={appleAuth}> <img src={Apple} alt="" />Se connecter avec Apple</button> */}
         {/* <hr />
         <p>Vous avez déjà un compte Oneclic.vet ? <span className="main-color" onClick={()=>navigate("/login")}>Connectez-vous</span></p> */}
         </div>

      </div>
    </div>
  );
};

export default ClientRegister;
