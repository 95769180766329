import { useNavigate } from "react-router-dom";
import "../../../styles/client/account/Change.scss";
import { AXIOS_POST } from "../../../config/axios";
import { POST_CHANGE_URL } from "../../../helper/Url";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../features/LanguageSlice";
import { useState } from "react";
import Loader from "../../../helper/Loader/Loader";
import Translate from "../../../config/Translate";
import FormErrors from "../../../helper/Message/FormErrors";

function Change() {
  const navigate = useNavigate();
  const [isSubmitted, setisSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  let token = localStorage.getItem("user_token");
  const languageValue = useSelector(selectLanguage);
  let requestError =
    languageValue === "En"
      ? "Something went wrong"
      : "Quelque chose s’est mal passé";
  let requestSuccess =
    languageValue === "En"
      ? "Password change successful"
      : "Changement de mot de passe réussi";

  const onSubmit = (data) => {
    setisSubmitted(true);
    AXIOS_POST(POST_CHANGE_URL, data, token)
      .then((res) => {
        toast.success(requestSuccess, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "colored",
        });
        setisSubmitted(false);
        setTimeout(() => navigate("/home", { replace: true }), 3500);
      })
      .catch((error) => {
        if (
          error.response.status === 400 ||
          error.response.data.statusCode === 400
        ) {
          toast.error(error.response.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
            theme: "colored",
          });
        } else {
          toast.error(requestError, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
            theme: "colored",
          });
        }
        setisSubmitted(false);
      });
  };

  return (
    <div className="change-container">
      <ToastContainer />
      <div className="change-wrapper">
       <span className="black font-16">Connexion à  <span className="main-color">Oneclic.vet</span></span> 
        <h1 className="main-color font-20">
        Entrez votre nouveau mot de passe
        </h1>
        <span className="black change-dialog">
        Entrez ci-dessous votre nouveau mot de passe et confirmez-le. Votre mot de passe doit contenir au moins 8 caractères avec une majuscule, une minuscule et un chiffre.</span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            type="password"
            placeholder="Mot de passe actuel"
            {...register("currentPassword", {
              required: true,
              pattern:
                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@)($%^&*-]).{8,}$/,
            })}
          />
          {errors.currentPassword?.type === "required" && (
            <FormErrors>
              <Translate Word={"page.register.password.error"} />
            </FormErrors>
          )}
          {errors.currentPassword?.type === "pattern" && (
            <FormErrors>
              <Translate Word={"page.register.passwordPatern.error"} />
            </FormErrors>
          )}
          <input
            type="password"
            placeholder="Nouveau mot de passe"
            {...register("password", {
              required: true,
              pattern:
                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@)($%^&*-]).{8,}$/,
            })}
          />
          {errors.password?.type === "required" && (
            <FormErrors>
              <Translate Word={"page.register.password.error"} />
            </FormErrors>
          )}
          {errors.password?.type === "pattern" && (
            <FormErrors>
              <Translate Word={"page.register.passwordPatern.error"} />
            </FormErrors>
          )}
          <input
            type="password"
            placeholder="Confirmer le mot de passe"
            {...register("confirmPassword", {
              required: true,
              validate: (val) => {
                if (watch("password") != val) {
                  return "Your passwords do no match";
                }
              },
            })}
          />
          {errors.confirmPassword?.type === "validate" && (
            <FormErrors>
              <Translate Word={"page.register.cpassword.error"} />
            </FormErrors>
          )}

          {isSubmitted ? (
            <button type="button">
              <Loader color="white" />
            </button>
          ) : (
            <button>
              <Translate Word={"page.change.password"} />
            </button>
          )}
        </form>
      </div>
    </div>
  );
}

export default Change;
