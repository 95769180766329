import React from "react";
import RiseLoader from "react-spinners/RiseLoader";

const Loader = ({ size = 8, loading = true, color = "#2CB5B2",cssOverride='' }) => {
  return (
    <>
      <RiseLoader color={color} size={size} loading={loading} cssOverride={cssOverride} />
    </>
  );
};

export default Loader;
