import React from "react";
import Translate from "../../../config/Translate";
import {
  GET_SINGLE_CLIENTS_URL,
  UPDATE_CLIENTS_URL,
  UPDATE_CLINIC_INFO_URL,
} from "../../../helper/Url";
import Loader from "../../../helper/Loader/Loader";
import { AXIOS_GET, AXIOS_PATCH } from "../../../config/axios";
import { useState } from "react";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import SuccessToast from "../../../helper/Message/SuccessToast";
import ErrorToast from "../../../helper/Message/ErrorToast";
import { selectBookinginfo } from "../../../features/SearchSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import FormErrors from "../../../helper/Message/FormErrors";
import { ReactComponent as AddRed } from "../../../assets/Icons/AddRed.svg";
import "../../../styles/client/common/PreInfo.scss";
import { selectUserInfo } from "../../../features/UserInfoSlice";

const ClientPreInfo = () => {
  const userFirstname = localStorage.getItem("user_firstname");
  const userLastname = localStorage.getItem("user_lastname");
  const token = localStorage.getItem("user_token");
  const bookingData = useSelector(selectBookinginfo).payload;
  const navigate = useNavigate();
  const id = localStorage.getItem("user_id");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ latitude: 0, longitude: 0 });
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value);
  };
 
  const onhandleSubmit = (data) => {
    setLoading(true);
    AXIOS_PATCH(UPDATE_CLIENTS_URL + `${id}`, data, token)
      .then((res) => {
        setLoading(false);
        localStorage.setItem("user_firstname", res.data.firstname);
        localStorage.setItem("user_lastname", res.data.lastname);
        SuccessToast("Vos informations ont été mises à jour avec succès");
       
        // if booking data contains informations then navigate to confirm booking page
        if (bookingData?.selectedDate?.length > 0) {
          setTimeout(() => {
            navigate("/search/confirm-booking");
          }, 100);
        }else{
            setTimeout(() => {
                navigate("/customer/dashboard/summary");
            }, 100);
        }
      })
      .catch((error) => {
        ErrorToast(error);
        setLoading(false);
      });
  };



  return (
    <div className="main-container">
      <ToastContainer />
      <div className="wrapper">
      <div className="Intro-title">
            <h1>Bienvenue <span>{userFirstname&&userFirstname} {userLastname&&userLastname}</span></h1>
            <p>Mes informations en un clic</p>
          </div>
      <div className="display-holder">
        <form className="infor-form" onSubmit={handleSubmit(onhandleSubmit)}>
          {/* <div className="input-container"
            style={{ alignItems: "baseline" }}
          >

              <label htmlFor="">Titre</label>
              <select
                className="margin-bottom-13"
                id=""
                name="title"
                {...register("title", { required: true })}
                disabled
                onMouseOver={(e) => e.target.disabled = false}
                value={formData.title}
                onChange={handleInputChange}
              >
                <option selected disabled hidden>
                  Titre
                </option>
                <option value="Dr">Dr.</option>
                <option value="Mme">Mme.</option>
                <option value="Mlle">Mlle.</option>
                <option value="M">M.</option>
                <option value="Me">Me.</option>
                <option value="Pr">Pr.</option>
              </select>
              {errors.title?.type === "required" && (
                <FormErrors>Le titre est requis</FormErrors>
              )}
          </div> */}

            <div className="input-container"
            style={{ alignItems: "baseline" }}
          >
              <label htmlFor="">
                <Translate Word={"page.datedenaissance"} />
              </label>
              <input
                className="margin-bottom-13" 
                type="date"
                placeholder="Votre date de naissance*"
                defaultValue="2017-05-24"
                {...register("birthdate", { required: true })}
                name="birthdate"
                onChange={handleInputChange}
              />
              {errors.birthdate?.type === "required" && (
                <FormErrors>Le date de naissance est requis</FormErrors>
              )}
          </div>


          <div className="input-container"
            style={{ alignItems: "baseline" }}
          >
              <label htmlFor="">
                <Translate Word={"page.prenom"} />
              </label>
              <input
                className="margin-bottom-13"
                type="text"
                placeholder="Votre prénom*"
                {...register("firstname", { required: true })}
                name="firstname"
                onChange={handleInputChange}
                value={formData.firstname}
              />
              {errors.firstname?.type === "required" && (
                <FormErrors>Le prénom est requis</FormErrors>
              )}
            </div>

            <div className="input-container"
            style={{ alignItems: "baseline" }}
          >
              <label htmlFor="">
                <Translate Word={"page.nodefamily"} />
              </label>
              <input
                className="margin-bottom-13"
                type="text"
                placeholder="Votre nom de famille*"
                {...register("lastname", { required: true })}
                name="lastname"
                onChange={handleInputChange}
                value={formData.lastname}
              />
              {errors.lastname?.type === "required" && (
                <FormErrors>Le nom de famille est requis</FormErrors>
              )}
            </div>

           <div className="input-container"
            style={{ alignItems: "baseline" }}
          >
            
              <label htmlFor="">
                <Translate Word={"page.address"} />
              </label>
              <input
                className="margin-bottom-13"
                type="text"
                placeholder="Votre adresse*"
                {...register("address", { required: true })}
                name="address"
                onChange={handleInputChange}
                value={formData.address}
              />
              {errors.address?.type === "required" && (
                <FormErrors>L'adresse est requis</FormErrors>
              )}
            </div>

            <div className="input-container"
            style={{ alignItems: "baseline" }}
          >
            
              <label htmlFor="">
               Complément d’adresse
              </label>
              <input 
                className="margin-bottom-13"
                type="text"
                placeholder="Complément d’adresse"
                {...register("more_address")}
                name="more_address"
                onChange={handleInputChange}
                value={formData.more_address}
              />
              {errors.address?.type === "required" && (
                <FormErrors>L'adresse est requis</FormErrors>
              )}
            </div>

            <div className="input-container"
            style={{ alignItems: "baseline" }}
          >
              <label htmlFor="">Civilité*</label>
              <select
                className="margin-bottom-13"
                id=""
                name="civility"
                disabled
                onMouseOver={(e) => e.target.disabled = false}
                {...register("civility", { required: true })}
                onChange={handleInputChange}
                value={formData?.civility}
              >
                <option  selected disabled hidden >
                  Civilité*
                </option>
                <option value="Mr">Monsieur</option>
                <option value="Mrs">Madame</option>
                <option value="Ms">Mademoiselle</option>
              </select>
              {errors.civility?.type === "required" && (
                <FormErrors>La civilité est requis</FormErrors>
              )}
            </div>
          
            <div className="input-container"
            style={{ alignItems: "baseline" }}
          >
        
              <label htmlFor="">
                <Translate Word={"page.postalcode"} />
              </label>
              <input
                className="margin-bottom-13"
                type="text"
                placeholder="Votre code postal*"
                name="postalCode"
                {...register("postalCode", { required: true })}
                onChange={handleInputChange}
                value={formData.postalCode}
              />
              {errors.postalCode?.type === "required" && (
                <FormErrors>Carte postal est requis</FormErrors>
              )}
            </div>
            <div className="input-container"
            style={{ alignItems: "baseline" }}
          >
              <label htmlFor="">
                <Translate Word={"page.ville"} />
              </label>
              <input
                className="margin-bottom-13"
                type="text"
                placeholder="Votre ville*"
                name="city"
                {...register("city", { required: true })}
                onChange={handleInputChange}
                value={formData.city}
              />
              {errors.city?.type === "required" && (
                <FormErrors>La ville est requis</FormErrors>
              )}
            </div>
            <div className="input-container"
            style={{ alignItems: "baseline" }}
          >

              <label htmlFor="">
                <Translate Word={"page.pay"} />
              </label>
              <select
                className="margin-bottom-13"
                id=""
                name="country"
                disabled
                onMouseOver={(e) => e.target.disabled = false}
                {...register("country", { required: true, value: "Suisse"})}
                onChange={handleInputChange}
                value={formData.country}
              >
                <option value="" selected disabled>
                  Votre pays*
                </option>
                <option value="Suisse">Suisse</option>
              </select>
              {errors.country?.type === "required" && (
                <FormErrors>Le pays est requis</FormErrors>
              )}
            </div>
            
          <div className=" btn-container">
            {loading ? (
              <button type="button">
                <Loader color="#ffffff"></Loader>
              </button>
            ) : (
              <button>Valider les modifications</button>
            )}
          </div>
        </form>
        {/*  for booking data */}
      </div>
      </div>
     
    </div>
  );
};

export default ClientPreInfo;
