import React, { useState } from "react";
import House from "../../../assets/Icons/clinic_house.png";
import Map from "../../../components/client/common/Map";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSearchResult,
  setBookingInfo,
} from "../../../features/SearchSlice";
import { ValidateValue } from "../../../helper/Message/ValidateValue";
import { Timetable } from "./Timetable/Timetable";
import { AXIOS_GET } from "../../../config/axios";
import { Base_Url } from "../../../config/variables";
import Loader from "../../../helper/Loader/Loader";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { set } from "react-hook-form";
import { GET_SLOTS_AVAILABILITY } from "../../../helper/Url";
import { Tooltip } from "react-tooltip";
import TimetableSlots from "./Timetable/TimetableSlots";
import NotActiveTimetable from "./Timetable/NotActiveTimetable";

export const GeneralSearchCard = ({ ClinicIdSearch, item, index, searchAddress, isInactive }) => {
  const [viewmore, setviewmore] = useState(1);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [startTimeObj, setstartTimeObj] = useState({});
  const [endTimeObj, setendTimeObj] = useState({});
  const [day, setday] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [providers, setProviders] = useState([]);
  const [services, setServices] = useState([]);
  const [provider, setProvider] = useState([]);
  const [service, setService] = useState([]);
  const [retrieveTimetable, setRetrieveTimetable] = useState(null);
  const [place, setPlace] = useState("");
  const [animalType, setAnimalType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // get device width
  const [width, setWidth] = useState(window.innerWidth);
  const updateWidthAndHeight = () => {
    setWidth((prevWidth) => {
      return (prevWidth = window.innerWidth);
    });
  };
  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, []);

  const [selectloader, setSelectloader] = useState(false);

  // clic Data that will have clinic id, service , provider info so that

  if (refresh) {
    setRefresh(false);
  }

  const handlebooking = (id, date, time, startTimeObj, endTimeObj, day) => {
    localStorage.setItem("selectedcliniqueId", id);
    setSelectedDate(date);
    setSelectedTime(time);
    setstartTimeObj(startTimeObj);
    setendTimeObj(endTimeObj);
    setday(day);
  };

  const searchResult = useSelector(selectSearchResult).payload;
  const handleClinicDetails = (clinicId) => {
    const clinic = searchResult.find(clinic => clinic._id === clinicId);
    if (clinic) {
      localStorage.setItem("clinic", JSON.stringify(clinic));
      navigate(`/cliniques/${clinic.facilityName.toLowerCase().replace(/\s+/g, '-')}`);
    }
  };


  const resetselectedDate = () => {
    setSelectedDate(null);
    setSelectedTime(null);
    setstartTimeObj({});
    setendTimeObj({});
    setday(null);
  };

  const getslotsavailable = (id) => {
    setSelectloader(true);
    const date = new Date().toISOString().slice(0, 10);
    AXIOS_GET(
      GET_SLOTS_AVAILABILITY +
      `?clinicId=${id}&startDate=${date}${service._id ? "&serviceId=" + service._id : ""
      }${provider._id ? "&providerId=" + provider._id : ""}${animalType ? "&species=" + animalType : ""
      }`
    )
      .then((res) => {
        setSelectloader(false);
        setstartTimeObj(startTimeObj);
        setendTimeObj(endTimeObj);
        setday(day);
        setRetrieveTimetable(res.data);
      })
      .catch((err) => {
        setSelectloader(false);
      });
  };

  const weekTimeTable = (id, TodayDate) => {
    setIsLoading(true);
    setSelectloader(true);
    const date = TodayDate;
    AXIOS_GET(
      GET_SLOTS_AVAILABILITY +
      `?clinicId=${id}&startDate=${date}`
    )
      .then((res) => {
        setSelectloader(false);
        setstartTimeObj(startTimeObj);
        setendTimeObj(endTimeObj);
        setday(day);
        setRetrieveTimetable(res.data.availableSlots);
      })
      .catch((err) => {
        setSelectloader(false);
      }).finally(() => {
        setIsLoading(false);
      });

  };

  //   get available slots for the selected date, service and provider

  // useEffect(() => {
  //     if(service._id || provider._id){
  //         getslotsavailable(item._id);
  //     }
  // }, [service,provider]);

  const handleSubmit = (
    id,
    firstname,
    lastname,
    city,
    country,
    postalcode,
    address,
    picture,
    facilityName,
    latitude,
    longitude,
    e
  ) => {
    if (e) {
      e.preventDefault();
    }

    setLoading(true);
    dispatch(
      setBookingInfo({
        id,
        firstname,
        lastname,
        city,
        country,
        selectedDate,
        selectedTime,
        startTimeObj,
        endTimeObj,
        picture,
        postalcode,
        address,
        day,
        facilityName,
        latitude,
        longitude,
        selectedProvider: [provider],
        selectedService: [service],
      })
    );
    setTimeout(() => {
      setLoading(false);
      localStorage.removeItem("selectedcliniqueId");
      navigate("/search/next-booking");
    }, 0);
  };

  const [isHovered, setisHovered] = useState(false);
  const [itemindex, setitemindex] = useState();
  const itemhover = (index) => {
    setisHovered(true);
    setitemindex(index);
  };

  const itemunhover = (index) => {
    setisHovered(false);
    setitemindex("");
  };


  // get searchspecie from local storage
  useEffect(() => {
    if (localStorage.getItem("searchSpecies")) {
      setAnimalType(localStorage.getItem("searchSpecies"));
    }
  }, [localStorage.getItem("searchSpecies")]);

  const incrimentViewmore = () => {
    // Get operating hours from item or use defaults
    const startHour = item?.operatingHours?.startTime?.hours ?? 7;
    const endHour = item?.operatingHours?.endTime?.hours ?? 17;

    // Calculate the number of groups (each group has 5 slots)
    const totalGroups = Math.ceil((endHour - startHour + 1) / 5);

    if (viewmore >= totalGroups) {
      // Reset to first group when we reach the end
      setviewmore(1);
    } else {
      setviewmore(viewmore + 1);
    }
  };

  function formatNumber(number) {
    // Remove any non-digit characters from the input number
    const cleanNumber = number.replace(/\D/g, "");

    // Check if the number starts with '41'
    if (cleanNumber.startsWith("41")) {
      // Format the number as +41 xx xxx xx xx
      return `+41 ${cleanNumber.slice(2, 4)} ${cleanNumber.slice(
        4,
        7
      )} ${cleanNumber.slice(7, 9)} ${cleanNumber.slice(9)}`;
    } else {
      // Return the original number if it doesn't start with '41'
      return number;
    }

  }

  return (
    <>
      {(ClinicIdSearch == null || ClinicIdSearch == item._id) &&
        (item.availableSlots?.length > 0 && item.isActive) && (
          <div
            className="search-card-container"
            onMouseEnter={() => itemhover(index)}
            onMouseLeave={() => itemunhover(index)}
            onTouchStart={() => itemhover(index)}
            onTouchEnd={() => itemunhover(index)}
          >
            <div className="result-container">
              {(item.availableSlots?.length > 0) && (
                <div className="info-result">
                  <div className="search-info">
                    <div className="img-container">
                      <img
                        className="w-full h-full object-cover border-2 border-[#2CB5B2] rounded-lg"
                        src={
                          item.picture.includes("http")
                            ? item.picture
                            : `${Base_Url}/${item.picture}`
                        }
                        alt=""
                      />
                    </div>
                    <div className="flex gap-x-2 justify-start items-start w-full">
                      <img src={House} alt="Clinic Icon" className="w-7 h-6" />

                      <div className="info">
                        <div className="title">
                          {/* <span className="strong">Clinique</span>{" "} */}
                          <span className="main-color hover:text-[#2CB5B2] strong cursor-pointer" onClick={() => handleClinicDetails(item._id)}>
                            {<ValidateValue value={item.facilityName} />}
                          </span>
                        </div>
                        <p
                          className="address"
                          style={{
                            fontWeight: 400,
                            fontSize: "13px",
                            textAlign: "start",
                          }}
                        >
                          {<ValidateValue value={item.address} />}{" "}
                          {/* {item.postalcode} {item.city} -{" "} */}
                          {/* <ValidateValue value={item.country} /> */}
                        </p>
                      </div>
                    </div>
                    <div className="vet-schedule-meeting">
                      <form
                        onSubmit={(e) =>
                          handleSubmit(
                            item._id,
                            item.firstname,
                            item.lastname,
                            item.city,
                            item.country,
                            item.postalcode,
                            item.address,
                            item.picture,
                            item.facilityName,
                            item?.geolocation?.coordinates[1],
                            item?.geolocation?.coordinates[0],
                            e
                          )
                        }
                      >
                        <div className="prv-web">
                          {loading &&
                            item._id ==
                            localStorage.getItem("selectedcliniqueId") ? (
                            <button>
                              <Loader color="white" />
                            </button>
                          ) : // show button when only date is selected, and on that item the service and provider is selected

                            selectedDate &&
                              !selectloader &&
                              item._id ==
                              localStorage.getItem("selectedcliniqueId") ? (
                              <button>Prendre rendez-vous</button>
                            ) : (
                              <>
                                <button
                                  disabled
                                  data-tooltip-id={item._id}
                                  style={{
                                    background: "grey",
                                    borderColor: "grey",
                                    opacity: "0.5",
                                    cursor: "not-allowed !important",
                                  }}
                                >
                                  Prendre rendez-vous
                                </button>
                                <Tooltip
                                  id={item._id}
                                  place="top"
                                  effect="solid"
                                  style={{
                                    height: "52px",
                                    display: "grid",
                                    placeContent: "center",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <span>Sélectionnez l'heure de réservation</span>
                                </Tooltip>
                              </>
                            )}
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="search-time" >
                    
                    <div className=" overflow-x-scroll w-full">
                      <div className="flex flex-nowrap min-w-max">
                        <TimetableSlots
                          isLoading={isLoading}
                          slots={item.availableSlots}
                          viewmore={viewmore}
                          id={item._id}
                          slotInterval={item.slotInterval || 20}
                          passedLoader={selectloader}
                          handlebooking={handlebooking}
                          onretrieve={retrieveTimetable}
                          resetselectedDate={resetselectedDate}
                          sysWidthy={width}
                          weekTimeTable={weekTimeTable}
                          item={item}
                          operatingHours={item.operatingHours}
                        />
                      </div>
                    </div>


                    <p>
                      <div className="prv-mobo">
                        {loading &&
                          item._id ==
                          localStorage.getItem("selectedcliniqueId") ? (
                          <button>
                            <Loader color="white" />
                          </button>
                        ) : selectedDate &&
                          !selectloader &&
                          item._id ==
                          localStorage.getItem("selectedcliniqueId") ? (
                          <button
                            onClick={(e) =>
                              handleSubmit(
                                item._id,
                                item.firstname,
                                item.lastname,
                                item.city,
                                item.country,
                                item.postalcode,
                                item.address,
                                item.picture,
                                item.facilityName,
                                item?.geolocation?.coordinates[1],
                                item?.geolocation?.coordinates[0],
                                e
                              )
                            }
                          >
                            Prendre rendez-vous
                          </button>
                        ) : (
                          <>
                            <button
                              disabled
                              data-tooltip-id={item._id}
                              style={{
                                background: "grey",
                                borderColor: "grey",
                                opacity: "0.5",
                                cursor: "not-allowed !important",
                              }}
                            >
                              Prendre rendez-vous
                            </button>
                            <Tooltip
                              id={item._id}
                              place="top"
                              effect="solid"
                              style={{
                                height: "52px",
                                display: "grid",
                                placeContent: "center",
                                borderRadius: "5px",
                              }}
                            >
                              <span>Sélectionnez l'heure de réservation</span>
                            </Tooltip>
                          </>
                        )}
                      </div>
                      Si vous ne trouvez pas de motif ou de créneau horaire à
                      votre convenance, merci de contacter votre vétérinaire au
                      :{" "}
                      <span className="mobo-num">
                        <br />
                        <a
                          href={
                            item.telephone1
                              ? `tel:${formatNumber(item.telephone1)}`
                              : "tel:021 123 45 67"
                          }
                          className="telNumber"
                        >
                          {item.telephone1
                            ? formatNumber(item.telephone1)
                            : "021 123 45 67"}{" "}
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
              )}
            </div>

            {item.availableSlots?.length > 0 && (
              <div
                className={
                  isHovered && itemindex == index
                    ? "result-map visible "
                    : "result-map visible"
                }
              >
                <Map
                  height={width < 1200 ? 200 : "calc(100% - 40px)"}
                  address={item.address}
                  city={item.city}
                  postalCode={item.postalcode}
                />
              </div>
            )}
          </div>
        )}

      {!item.availableSlots?.length > 0 && !item.isActive && (
        <div className="search-card-container">
          <div className="result-container" >
            <NotActiveTimetable clinic={item} />
          </div>
          {/* <div className="result-map">
              <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                <span className="text-gray-500">
                  Clinique non active
                </span>
                  </div>
            </div> */}
        </div>
      )}
    </>
  );
};
