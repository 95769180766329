import React, { useState, useEffect, useRef } from "react";
import { GET_CLINIC_NAMES_URL } from "../../../helper/Url";
import { AXIOS_GET } from "../../../config/axios";
import { useDispatch, useSelector } from "react-redux";
import { selectQueryParams, setQueryParams } from "../../../features/SearchSlice";

function ClinicAutocomplete({ setClinicName }) {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [clinics, setClinics] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const queryParams = useSelector(selectQueryParams);

  // Add useRef for the component container
  const wrapperRef = useRef(null);

  useEffect(() => {
    AXIOS_GET(GET_CLINIC_NAMES_URL).then((res) => {
      setClinics(res.data);
      setSuggestions(res.data);
    });
  }, []);

  // Add useEffect for click outside handling
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    // Filter suggestions based on input
    const filtered = clinics.filter(clinic =>
      clinic.facilityName && clinic.facilityName.toLowerCase().includes(newValue.toLowerCase())
    );
    setSuggestions(filtered);
    // Don't update clinicName until a suggestion is selected
    setShowSuggestions(true);
  };

  const handleSuggestionClick = (suggestion, index) => {
    setInputValue(suggestion.facilityName);
    setClinicName(suggestion.facilityName);
    setShowSuggestions(false);
    setSelectedIndex(index);
  };

  // Add click handler to show all suggestions
  const handleInputClick = () => {
    setShowSuggestions(true);
    setSuggestions(clinics); // Show all clinics when clicking the input
  };

  return (
    <div ref={wrapperRef} style={{ position: "relative" }} className="w-full" >
      <div className="relative w-full">
        <input
          type="text"
          placeholder="NOM OU ÉTABLISSEMENT"
          id="personal"
          onChange={handleInputChange}
          onClick={handleInputClick}
          value={inputValue}
          onFocus={handleInputClick}
          className={` w-full h-full px-4 py-2 hover:text-gray-300 transition hover:placeholder:text-gray-700 text-sm outline-none font-normal cursor-pointer placeholder:font-normal text-black bg-white ${showSuggestions ? 'placeholder:text-gray-400' : 'placeholder:text-black' }`}
        />
        {inputValue && (
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setInputValue('');
              setSuggestions([]);
              setShowSuggestions(false);
              setClinicName('');
            }}
            className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
            style={{
              width: "20px",
              height: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#e0e0e0",
              borderRadius: "50%",
              color: "#666",
              fontSize: "14px",
              zIndex: 1000,
              pointerEvents: "auto"
            }}
          >
            ✕
          </div>
        )}
      </div>
      {showSuggestions && suggestions.length > 0 && (
        <div className="absolute top-full left-0 right-0 bg-white border-r-2 border-r-[#2CB5B2]  z-[1000] max-h-[300px] overflow-y-auto mt-2">
          {suggestions.map((suggestion, index) => (
            suggestion.facilityName && (
              <div
                key={index}
                className={`flex items-center cursor-pointer p-2 text-sm text-black text-left hover:text-[#2CB5B2] font-normal
                ${selectedIndex === index ? 'text-[#2CB5B2]' : 'bg-white'}
                ${index === 0 ? 'border-t border-[#2CB5B2]' : ''}`}
                onClick={() => handleSuggestionClick(suggestion, index)}
              >
                <img
                  src={`https://v0.oneclic.vet/${suggestion.picture}`}
                  alt={suggestion.facilityName}
                  className="w-6 h-6 rounded-full mr-2"
                />
                {`${suggestion.facilityName}`.toUpperCase()}
              </div>
            )
          ))}
        </div>
      )}
    </div>
  );
}

export default ClinicAutocomplete;