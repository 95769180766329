import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HomeContainer from "../../../components/client/container/HomeContainer";
import { selectLanguage } from "../../../features/LanguageSlice";
import "../../../styles/search/General.scss";
import { SearchCard } from "./SearchCard";
import { useLocation } from "react-router-dom";
import {
  selectInactiveClinics,
  selectPagination,
  selectQueryParams,
  selectSearchResult,
  setInactiveClinics,
  setPagination,
  setSearchedClinics,
} from "../../../features/SearchSlice";
import { AXIOS_GET } from "../../../config/axios";
import { GET_SEARCH_CLINICS_NEAREST_WITH_SLOTS_URL } from "../../../helper/Url";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Loader from "../../../helper/Loader/Loader";

export const General = () => {
  const languageValue = useSelector(selectLanguage);
  const [place, setPlace] = React.useState("");
  const searchData = useSelector(selectSearchResult).payload;
  const bookPage = useRef();
  const attemptCount = useRef(0);
  const pagination = useSelector(selectPagination).payload;
  const searchParams = useSelector(selectQueryParams).payload;
  const [page, setPage] = useState(pagination?.currentPage || 1);
  const [loading, setLoading] = useState(false);
  const inactiveClinics = useSelector(selectInactiveClinics).payload;

  useEffect(() => {
    if (window.innerWidth > 800) {
      window.scrollTo({ top: 500, behavior: "smooth" });
    } else {
      window.scrollTo({ top: 325, behavior: "smooth" });
    }
  }, [searchData]);

  // Get the location object using the useLocation hook
  const location = useLocation();

  // Parse the search string to get the query parameters
  const queryParams = new URLSearchParams(location.search);

  // Get the clinicId parameter from the query parameters
  const ClinicIdSearch = queryParams.get("clinicId");
  const dispatch = useDispatch();

  let Title =
    languageValue === "En"
      ? "Trouvez un vétérinaire"
      : "Trouvez un vétérinaire";
  let Subtitle =
    languageValue === "En"
      ? "Prendre rendez-vous"
      : "Prendre rendez-vous";

  // get place from local storage
  useEffect(() => {
    if (localStorage.getItem("searchCodePostal")) {
      setPlace(localStorage.getItem("searchCodePostal"));
    }
  }, [localStorage.getItem("searchCodePostal")]);

  const headerTitle = () => {

  }
  const handleSubmit = (newPage) => {
    const params = new URLSearchParams();
    params.set("latitude", searchParams?.latitude || 0);
    params.set("longitude", searchParams?.longitude || 0);
    params.set("species", searchParams?.species || "");
    params.set("clinic", searchParams?.clinicName || "");
    params.set("city", searchParams?.city || "");
    params.set("postalCode", searchParams?.postalCode || "");
    params.set("page", newPage || page);
    const searchString = params.toString();
    AXIOS_GET(
      GET_SEARCH_CLINICS_NEAREST_WITH_SLOTS_URL +
        `?${searchString}`
    )
      .then((res) => {
        dispatch(setSearchedClinics(res.data.clinics));
        dispatch(setInactiveClinics(res.data.inactiveClinics));
        dispatch(setPagination({
          currentPage: newPage || page,
          totalPages: res.data.totalPages
        }));
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  const handlePageChange = (newPage) => {
    setLoading(true);
    setPage(newPage);
    handleSubmit(newPage);
  };

  const getSearchMessage = () => {
    const hasSearchResults = searchData?.length > 0;
    const hasInactiveClinics = inactiveClinics?.length > 0;
    const isClinicSearch = searchParams?.clinicName;
    const isCitySearch = searchParams?.city;

    if (!hasSearchResults && !hasInactiveClinics) {
      return (
        <div className="text-left my-4" style={{fontSize: "24px"}}>
          <span style={{fontSize: "20px", fontWeight: "bold", color: "black"}} className="text-black block">Aucun vétérinaire ne correspond à votre recherche.</span>
          <span style={{fontSize: "20px", color: "black", fontWeight: "200"}} className="text-black block">Votre vétérinaire n'est peut être pas encore réservable en ligne sur Oneclic.vet</span>
        </div>
      );
    }

    if (isClinicSearch && !isCitySearch) {
      return (
        <span style={{fontSize: "24px", fontWeight: "bold", color: "black"}}>Nous avons trouvé un vétérinaire correspondant à votre recherche.</span>
      );
    }

    if (isCitySearch) {
      return (
          <span style={{fontSize: "24px", fontWeight: "bold", color: "black"}}>Rendez-vous vétérinaire à <span style={{color: "#2CB5B2", fontWeight: "bold", fontSize: "24px"}}>{searchParams.city}</span> avec réservation en ligne.</span>
      );
    }

    return <span style={{fontSize: "24px", fontWeight: "bold", color: "black"}}>Prenez rendez-vous en ligne avec un vétérinaire </span> ;
  };

  // check if searchData is empty or null and submit the form

  useEffect(() => {
    if (
      searchData === null ||
      searchData === undefined ||
      searchData.length === 0
    ) {
      if (attemptCount.current < 2) {
        handleSubmit();
        attemptCount.current += 1;
      }
    }
  }, [searchData]);

  return (
    <div className="change-container">
      <HomeContainer Title={Title} Subtitle={Subtitle} display_map="false">
        <div className="search-container" ref={bookPage}>
          <div className="intro">
            <h3>
              {getSearchMessage()}
            </h3>
          </div>
          <SearchCard ClinicIdSearch={ClinicIdSearch} attemptCount={attemptCount} />
          {/* width 100 on mobile */}
          <div className="w-full md:w-80%" style={{ padding: "30px"}}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>{loading && <Loader />}</div>
            <div className="flex justify-center mt-4 space-x-2">
              <button
              className="p-2 bg-gray-200 rounded-full hover:bg-gray-300 disabled:opacity-50"
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1 || loading}
            >
              <IoIosArrowBack />
            </button>
            {Array.from({ length: pagination?.totalPages }, (_, i) => i + 1)
              .slice(Math.max(0, page - 4), Math.min(pagination?.totalPages, page + 3))
              .map((pageNumber) => (
                <span
                  key={pageNumber}
                  className={`px-4 py-2 mx-1 rounded-full cursor-pointer ${
                    pageNumber === page ? 'bg-[#2CB5B2] text-white' : 'bg-gray-200'
                  }`}
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </span>
              ))}
            <button
              className="p-2 bg-gray-200 rounded-full hover:bg-gray-300 disabled:opacity-50"
              onClick={() => handlePageChange(page + 1)}
              disabled={page === pagination?.totalPages || loading}
            >
                <IoIosArrowForward />
              </button>
            </div>
          </div>
        </div>
      </HomeContainer>
    </div>
  );
};
