// Define URLs for different environments
// export const Base_Url = 'https://v0.oneclic.vet';
export const Base_Url = (() => {
    switch (process.env.REACT_APP_ENV) {
      case 'production':
        return 'https://live-apis.oneclic.vet';
      case 'development':
        return 'https://v0.oneclic.vet';
      case 'uat':
        return 'https://uat-apis.oneclic.vet';
      default:
        return 'http://localhost:8000';
    }
  })();