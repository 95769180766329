import React, { useEffect } from "react";
import { GeneralSearchCard } from "./GeneralSearchCard";
import { selectInactiveClinics, selectSearchResult } from "../../../features/SearchSlice";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import PropTypes from 'prop-types';

export const SearchCard = ({ ClinicIdSearch, attemptCount }) => {
  const searchData = useSelector(selectSearchResult).payload;
  const inactiveClinics = useSelector(selectInactiveClinics).payload;

  useEffect(() => {
    console.log("==========searchData===========", searchData);
  }, [searchData]);

  const renderSearchResults = () => {
    if (searchData?.length > 0) {
      return searchData.map((item, index) => (
        <GeneralSearchCard
          key={item.id || item.clinicId || `clinic-${item.facilityName}`}
          ClinicIdSearch={ClinicIdSearch}
          item={item}
          index={index}
        />
      ));
    }
    return null;
  };

  return (
    <div>
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {renderSearchResults()}
      {inactiveClinics?.length > 0 && searchData?.length > 0 && (<div className="w-[80%]" style={{ margin: "auto" }}><p className="text-center text-8xl font-bold mt-2">Autres cliniques vétérinaires</p></div>)}
      {inactiveClinics?.map((item, index) => (
        <GeneralSearchCard
          key={item.id}
          ClinicIdSearch={ClinicIdSearch}
          item={item}
          isInactive={false}
          index={index}
        />
      ))}
    </div>
  );
};

SearchCard.propTypes = {
  ClinicIdSearch: PropTypes.string,
  attemptCount: PropTypes.number
};

SearchCard.defaultProps = {
  ClinicIdSearch: null,
  attemptCount: 0
};  
