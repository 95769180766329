import React from 'react'
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AXIOS_POST } from "../../../../config/axios";
import { POST_REGISTER_GOOGLE_URL, POST_REGISTER_URL } from "../../../../helper/Url";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Translate from "../../../../config/Translate";
import Loader from "../../../../helper/Loader/Loader";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../../features/LanguageSlice";
import FormErrors from "../../../../helper/Message/FormErrors";
import "../../../../styles/client/account/MainRegister.scss";
import SuccessToast from "../../../../helper/Message/SuccessToast";
import Google from '../../../../assets/Icons/logo googleg 48dp.png';
import Facebook from '../../../../assets/Icons/Facebook Logo.png';
import Apple from '../../../../assets/Icons/Apple Logo.png';
import { authPop, loginWithApple, loginWithFacebook } from "../../../../firebase";
import ErrorToast from "../../../../helper/Message/ErrorToast";



const PreClientRegister = () => {
    const navigate = useNavigate();
    const [isSubmited, setIsSubmited] = useState(false);
    const languageValue = useSelector(selectLanguage);
  
    let accountCreated =
      languageValue === "En"
        ? "Account created successful"
        : "Compte créé avec succès";
  
    const {
      register,
      handleSubmit,
      watch,
      formState: { errors },
    } = useForm();
  
    const onSubmit = (data) => {
      data.ide = "";
      data.type = 0;
      data.role = 10;
      data.latitude = 80;
      data.longitude = 120;
      setIsSubmited(true);
      AXIOS_POST(POST_REGISTER_URL, data, "", "")
        .then((res) => {
          localStorage.setItem("email", data.email);
          if (res.data.statusCode === 400) {
            SuccessToast(res.data.message)
          } else {
            SuccessToast(accountCreated)
            setTimeout(() => navigate("/verify", { replace: true }), 3500);
          }
          setIsSubmited(false);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
            theme: "colored",
          });
          setIsSubmited(false);
        });
    };
  
    const googleAuth=async ()=>{
      const user = await authPop();
      let data ={};
      data.type = 0;
      data.role = 10;
      setIsSubmited(true);
      AXIOS_POST(POST_REGISTER_GOOGLE_URL+`?type=${data.type}&role=${data.role}`, data, user.accessToken, "")
        .then((res) => {
            SuccessToast(accountCreated)
            setTimeout(
              () => navigate("/login", { replace: true }),
              0
            );
          
          setIsSubmited(false);
        })
        .catch((error) => {
          ErrorToast(error);
          setIsSubmited(false);
        });
    }
  
    return (
      <div className="register-container">
        <ToastContainer />
        <div className="register-inner-wrapper" data-aos="zoom-out-up">
          <div className="top-form-title">
            <Translate Word={"page.join"} />
            <span className="span-colored">Oneclic.vet</span>
          </div>
          <h1 className="lg-size">
            <Translate Word={"header.Register"} />
          </h1>
          <p className="text-center p-description">
          <strong>Prenez rendez-vous en ligne avec votre vétérinaire 24h/24 et 7j/7 en quelques clics !
  </strong>
            <br />
            Depuis n'importe quel appareil, prenez rendez-vous pour votre animal avec le vétérinaire disponible le plus proche de chez-vous.
  
            <br />
            <br />
            C'est simple, sécurisé et entièrement gratuit
          </p>
         
          <div className="authbtn-group">
           <button className="other-login other-login4 " onClick={()=>navigate("/register")}>S’INSCRIRE AVEC UN E-MAIL</button>
           <div className="separator">OU</div>
           {/* <button className="other-login other-login1" onClick={facebookAuth}> <img src={Facebook} alt="" />Se connecter avec Facebook</button> */}
           <button className="other-login other-login2" onClick={googleAuth}> <img src={Google} alt="" />Se connecter avec Google</button>
           {/* <button className="other-login other-login3" onClick={appleAuth}> <img src={Apple} alt="" />Se connecter avec Apple</button> */}
            <hr />
            <p>Vous avez déjà un compte Oneclic.vet ? <span className="main-color" onClick={()=>navigate("/login")}>Connectez-vous</span></p>
           </div>
  
        </div>
      </div>
    );
  };
  

export default PreClientRegister
