import Map from "../../../components/client/common/Map";
import Header from "../../../components/client/common/Header";
import Footer from "../../../components/client/common/Footer";
import BannerHome from "../common/BannerHome";

const HomeContainer = ({ Title, Subtitle, display_map=true, children}) => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <div className="flex-1">
        <BannerHome Title={Title} Subtitle={Subtitle} />
        {children}
        {/* {display_map == true? <Map/>:''} */}
      </div>
      <Footer />
    </div>
  );
};

export default HomeContainer;
