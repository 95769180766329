import React from "react";
import { Base_Url } from "../../../../config/variables";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedInactiveClinic } from "../../../../features/SearchSlice";
const NotActiveClinicInfo = ({ clinic }) => {
  // Function to capitalize the first letter of each word
  const capitalize = (text) => {
    return text.replace(/\b\w/g, char => char.toUpperCase());
  };

  const navigate = useNavigate();

  // Set selected clinic state
  const dispatch = useDispatch();
  const handleViewProfile = () => {
    localStorage.setItem("clinic", JSON.stringify(clinic));
    // Name of clinic has to be in small letters and space "-"
    const clinicName = clinic.facilityName.toLowerCase().replace(/\s+/g, '-');
    navigate(`/cliniques/${clinicName}`);
  };

  return (
    <div className="w-full h-full flex lg:flex-row flex-col items-start justify-between p-4 flex-wrap bg-white shadow-md rounded-lg">
      <div className="flex items-center lg:w-1/2 w-full" >
        <img
          src={
            clinic.picture.includes("http")
              ? clinic.picture
              : `${Base_Url}/${clinic.picture}`
          }
          alt="Clinic Logo"
          className="w-10 h-10 mr-3 rounded-full border-2 border-[#2CB5B2]"
        />
        <div className="flex items-start justify-start w-full">
          <span className="text-sm lg:text-lg font-bold text-left text-[#2CB5B2] w-full">
            {clinic.facilityName}
          </span>
        </div>
      </div>
      <div className="flex lg:flex-row flex-col items-center justify-between gap-y-4 lg:mt-0 mt-2 lg:w-1/2 w-full" >
        <div className="text-gray-600 text-sm text-start lg:text-left px-14 lg:pl-[5%] w-full" >
          <div>{capitalize(clinic.address.toLowerCase())}</div>
          <div>{clinic.postalCode} {capitalize(clinic.city.toLowerCase())}</div>
        </div>
        <div className="flex items-center justify-end text-gray-600 text-sm text-left lg:pl-[5%]" >
          <button onClick={handleViewProfile} className="bg-teal-400 h-10 w-40 text-white py-1 px-3 text-bold rounded-md text-sm">
            VOIR LA FiCHE 
          </button>
        </div>
      </div>
      <div className="text-gray-600 text-sm text-left" style={{ width: '45%', paddingLeft: '5%' }}>
        </div>  
      {/* <button className="bg-teal-400 text-white py-1 px-3 rounded-md text-sm" style={{ width: '15%' }}>
        Voir profil
      </button> */}
    </div>
  );
};

export default NotActiveClinicInfo;
