import { useEffect, useState } from "react";
import PersonIconW from "../../../assets/Icons/personW.svg";
import PesonIconC from "../../../assets/Icons/personc.svg";
import PawIconW from "../../../assets/Icons/pawW.svg";
import PawIconC from "../../../assets/Icons/paw.svg";
import MapIconW from "../../../assets/Icons/map_pointerW.svg";
import MapIconC from "../../../assets/Icons/map_pointer.svg";
import SearchIconBlue from "../../../assets/Icons/searchBlue.svg";
import SearchIconW from "../../../assets/Icons/search.svg";
import { AXIOS_GET } from "../../../config/axios";
import { GET_CLINIC_CITIES_URL, GET_CLINIC_NAMES_URL } from "../../../helper/Url";
import Loader from "../../../helper/Loader/Loader";
import { set } from "nprogress";

const MobileSearch = ({
  setIsTitlesVisible,
  setClinicHandler,
  setAddressHandler,
  setCityHandler,
  setPostalHandler,
  setAnimalHandler,
  onSearch,
  loading
}) => {
  const [expandedSection, setExpandedSection] = useState(null);
  const [formData, setFormData] = useState({
    clinic: '',
    location: '',
    animalType: ''
  });

  const [searchInputs, setSearchInputs] = useState({
    clinicSearch: '',
    locationSearch: ''
  });
  const [clinics, setClinics] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    AXIOS_GET(GET_CLINIC_NAMES_URL).then((res) => {
      setClinics(res.data);
    });
    AXIOS_GET(GET_CLINIC_CITIES_URL).then((res) => {
      setCities(res.data);
    });
  }, []);

  const animals = [
    { value: "Chien", label: "Chien" },
    { value: "Chat", label: "Chat" },
    { value: "Lapin", label: "Lapin" },
    { value: "NAC", label: "NAC" }
  ];

  const filteredClinics = clinics.filter(clinic => clinic?.facilityName?.toLowerCase().includes(searchInputs.clinicSearch.toLowerCase()));
  const filteredCities = cities.filter(city => city?.city?.toLowerCase().includes(searchInputs.locationSearch.toLowerCase()));

  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
    setIsTitlesVisible(expandedSection === section ? true : false);
  };

  const handleInputChange = (field, value) => {
    setSearchInputs(prev => ({ ...prev, [`${field}Search`]: '' }));

    if (field === 'location') {
      console.log(value,"Value");
      
      const splitValue = value.split(',');
      const selectedCity = splitValue[0];
      const selectedPostalCode = splitValue[1]?.trim();
      setAddressHandler(value);
      setCityHandler(selectedCity);
      setPostalHandler(selectedPostalCode);
    }

    setFormData(prev => ({
      ...prev,
      [field]: value
    }));

    if (field === 'clinic') {
      setClinicHandler(value);
    } else if (field === 'animalType') {
      setAnimalHandler(value);
    }
  };

  const handleSearchInputChange = (e, field) => {
    const value = e.target.value;
    setSearchInputs(prev => ({ ...prev, [field]: value }));
    setFormData(prev => ({ ...prev, [field === 'clinicSearch' ? 'clinic' : 'location']: '' }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(e);
    setExpandedSection(null);
  };

  const handleReset = () => {
    setFormData({
      clinic: '',
      location: '',
      animalType: ''
    });
    setSearchInputs({
      clinicSearch: '',
      locationSearch: ''
    });
    setExpandedSection(null);
    setAddressHandler('');
    setCityHandler('');
    setPostalHandler('');
  };
  // bg-[#2CB5B2]
  return (
    <>
      <div className="bg-transparent p-4  w-full flex items-center justify-center">
        <form onSubmit={handleSubmit} className="w-full md:hidden rounded-lg">
          {/* Header Section - Clinic */}
          <div
            onClick={() => toggleSection('clinic')}
            className="bg-white  py-3 w-full cursor-pointer border-b border-[#2CB5B2] rounded-t-lg"

          >
            <div className="flex w-full justify-start items-center gap-4 px-6">
              <img src={PesonIconC} alt="clinic" className="w-6 h-6" />
              <input
                type="text"
                placeholder="NOM OU ÉTABLISSEMENT"
                onFocus={() => toggleSection('clinic')}
                value={formData.clinic || searchInputs.clinicSearch}
                onChange={(e) => handleSearchInputChange(e, 'clinicSearch')}
                onClick={() => toggleSection('clinic')}
                className={` text-start bg-transparent text-black font-normal text-sm w-full focus:outline-none ${expandedSection === 'clinic' ? 'placeholder-gray-400' : ' placeholder-black'}`}
              />
              {expandedSection === 'clinic' && (
                <span
                  className="text-5xl transform rotate-90 cursor-pointer text-[#2CB5B2] leading-none font-light"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSection('clinic');
                  }}
                >
                  ›
                </span>
              )}
            </div>
          </div>

          {/* Clinic Expanded Section */}
          <div
            className={`overflow-hidden transition-all w-full duration-300 ease-in-out bg-white
          ${expandedSection === 'clinic' ? 'h-[15vh] overflow-y-scroll' : 'max-h-0'}`}
          >
            {filteredClinics.map((clinic, index) => (
              <div
                key={index}
                className="flex  justify-start gap-1 items-start py-2 px-6 w-full hover:bg-gray-50 cursor-pointer font-normal text-gray-700 border-b border-gray-100 text-start"
                onClick={() => {
                  handleInputChange('clinic', clinic.facilityName);
                  toggleSection(null);
                }}
              >
                <img
                  src={`https://v0.oneclic.vet/${clinic.picture}`}
                  alt={clinic.facilityName}
                  className="w-6 h-6 rounded-full mr-2"
                />
                {clinic.facilityName}
              </div>
            ))}
          </div>

          {/* Header Section - Location */}
          <div
            onClick={() => toggleSection('location')}
            className="bg-white  py-3 w-full cursor-pointer border-b border-[#2CB5B2]"
          >
            <div className="flex w-full justify-start items-center gap-4 px-6">
              <img src={MapIconC} alt="location" className="w-6 h-6" />
              <input
                type="text"
                placeholder="CODE POSTAL OU VILLE"
                value={formData.location || searchInputs.locationSearch}
                onChange={(e) => handleSearchInputChange(e, 'locationSearch')}
                onClick={() => toggleSection('location')}
                onFocus={() => toggleSection('location')}
                className={`text-start bg-transparent text-black font-normal text-sm w-full focus:outline-none ${
                  expandedSection === 'location' ? 'placeholder-gray-400' : 'placeholder-black'
                }`}
              />
              {expandedSection === 'location' && (
                <span
                  className="text-5xl transform rotate-90 cursor-pointer text-[#2CB5B2] leading-none font-light"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSection('location');
                  }}
                >
                  ›
                </span>
              )}
            </div>
          </div>

          {/* Location Expanded Section */}
          <div
            className={`overflow-hidden w-full transition-all duration-300 ease-in-out bg-white
          ${expandedSection === 'location' ? 'h-[15vh] overflow-y-scroll' : 'max-h-0'}`}
          >
            {filteredCities.map((city, index) => (
              <div
                key={index}
                className="flex justify-start gap-1 items-start py-2 px-4  hover:bg-gray-50 cursor-pointer font-normal text-gray-700 border-b border-gray-100 text-start"
                onClick={() => {
                  handleInputChange('location', `${city.city}, ${city.postalCode}`);
                  toggleSection(null);
                }}
              >
                {city.city}, {city.postalCode}
              </div>
            ))}
          </div>

          {/* Header Section - Animal */}
          <div
            onClick={() => toggleSection('animal')}
            className="bg-white  py-3 w-full cursor-pointer border-b border-[#2CB5B2]"
          >
            <div className="flex w-full justify-start items-center gap-4 px-6">
              <img src={PawIconC} alt="location" className="w-6 h-6" />
              <input
                type="text"
                placeholder="TYPE D'ANIMAL"
                value={formData.animalType || `TYPE D'ANIMAL`}
                readOnly
                // onChange={(e) => handleSearchInputChange(e, 'locationSearch')}
                // onClick={() => toggleSection('location')}
                // onFocus={() => toggleSection('location')}
                className={`text-start bg-transparent font-normal w-full text-sm  focus:outline-none ${expandedSection === 'animal' ? ' text-gray-400' : ' text-black'}`}
              />
              {expandedSection === 'animal' && (
                <span
                  className="text-5xl transform rotate-90 cursor-pointer text-[#2CB5B2] leading-none font-light"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSection('animal');
                  }}
                >
                  ›
                </span>
              )}
            </div>
          </div>

          {/* Animal Expanded Section */}
          <div
            className={`overflow-hidden transition-all w-full duration-300 ease-in-out bg-white
          ${expandedSection === 'animal' ? 'h-[15vh] overflow-y-scroll' : 'max-h-0'}`}
          >
            {animals.map((animal, index) => (
              <div
                key={index}
                className="flex justify-start gap-1 items-start py-2 px-4 w-full hover:bg-gray-50 cursor-pointer font-normal text-gray-700 border-b border-gray-100 text-start"
                onClick={() => {
                  handleInputChange('animalType', animal.value);
                  toggleSection(null);
                }}
              >
                {animal.label}
              </div>
            ))}
          </div>

          {/* Search Button Section */}
          <div className="flex justify-center w-full bg-white py-4 gap-x-2 px-3 rounded-b-lg ">

            <button
              onClick={() => handleReset()}
              type="button"
              className="w-full flex  items-center justify-center text-xs bg-white rounded-full text-[#2CB5B2] font-medium"
            >
              Réinitialiser
            </button>
            {
              loading ?
                (
                  <>
                    <div

                      className="w-40 flex items-center justify-center text-xs rounded-lg bg-[#2CB5B2] text-white font-medium"
                    // onClick={() => handleSubmit()}
                    >
                      <Loader size={12} cssOverride={{ transform: "scale(0.3)" }} color="white" />
                    </div>
                  </>
                ) :
                (
                  <button
                    type="submit"
                    className="w-40 py-4 px-4 gap-x-2 flex items-center justify-center text-xs rounded-lg bg-[#2CB5B2] text-white font-medium"
                  // onClick={() => handleSubmit()}
                  >
                    <img src={SearchIconW} alt="search" className="w-5 h-5" />
                    RECHERCHER
                  </button>
                )
            }

          </div>
        </form>

      </div>
    </>

  );
};

export default MobileSearch;