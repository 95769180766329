import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PhoneField = ({telephone="",settelephone="",containerClass,inputClass,buttonClass,inputStyle,buttonStyle,containerStyle,placeholder="",required=false}) => {
 

  const handleOnChange = (value) => {
    if(settelephone !== undefined ){
    settelephone(value);
  }
    }


  return (
    <PhoneInput
        country={'ch'}
        value={telephone}
        onChange={handleOnChange}
        containerClass={containerClass}
        inputClass="phoneCode"
        buttonClass={buttonClass}
        inputStyle={inputStyle}
        placeholder={placeholder}
        buttonStyle={buttonStyle}
        containerStyle={containerStyle}
        inputProps={{
            name: 'phone',
            required: required,
            autoFocus: false,
            
        }}
    />
  );
};

export default PhoneField;
