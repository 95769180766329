import "../../../styles/client/account/Register.scss";
import ClientContainer from "../../../components/client/container/Container";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../features/LanguageSlice";
import ClinicActivePageContent from "../../../components/client/clinic/ClinicActivePageContent";
import HomeContainer from "../../../components/client/container/HomeContainer";
import ClinicInactivePageContent from "../../../components/client/clinic/ClinicInactivePageContent";


function ClinicSummaryPage() {
  const languageValue = useSelector(selectLanguage);

  let Title = languageValue === "En" ? "Trouvez un vétérinaire" : "Trouvez un vétérinaire";
  let Subtitle =
    languageValue === "En"
      ? "Votre rendez-vous vétérinaire"
      : "Votre rendez-vous vétérinaire";

  return (
    <div className="account-page-container">
      <HomeContainer Title={Title} Subtitle={Subtitle}>
        <ClinicActivePageContent />
      </HomeContainer>
    </div>
  );
}

export default ClinicSummaryPage;
